import React, {Fragment, useContext, useEffect, useRef, useState} from 'react';
import {Link, NavLink} from "react-router-dom";
import {hasPermission, PermissionsWrapper} from '../../../../components/Dashboard/reactPermissionsWrapper';
import {AuthContext} from "../../../../context/Auth/AuthProvider";
import OrgCookieCrumb from "../../../../components/Dashboard/orgCookieCrumb";
import Swal from '@molline/sweetalert2';
import {useRouter} from "../../../../components/Shared/Router/Router";
import {TenantContext} from "../../../../context/Tenant/Tenant";
import {LocationsContext} from "../../../../context/Locations/Locations";
import {OrganizationContext} from '../../../../context/Organization/Organization';
import datepicker from 'js-datepicker'

import TourSteps from "../../../../TourSteps.json";
import PageTour from "../../../../components/Dashboard/tour/tour";
import CapitalizeUtils from "../../../../utils/capitalizeUtils"


const TenantDetails = (props) => {

    const router = useRouter()
    const [tid, setUid] = useState(router.query.tenant_id);
    const {user} = useContext(AuthContext);
    const {locations, getLocation} = useContext(LocationsContext);
    const {
        tenant, 
        getTenant, 
        updateTenant, 
        tenantChanged, 
        doYouReallyWantToSave,
        successToast,
        errorToast,
        formatDate
    } = useContext(TenantContext)
    const [tabActive, setTabActive] = useState('overview');
    const [isEditing, setIsEditing] = useState(false)

    const { organization} = useContext(OrganizationContext);
    const tenantTidChanged = useCompare(tid)
    const [editForm, setEditForm] = useState({})

    const [inDate, setInDate] = useState( (tenant?.in_date)? formatDate(tenant.in_Date):null )
    const [outDate, setOutDate] = useState( (tenant?.out_date)? formatDate(tenant.out_Date):null )
    const [location, setLocation] = useState({})

    useEffect(() => {

        if (tenantTidChanged) getTenant(tid)
        if(tenantChanged && tenant?.location_id){
            let tenantLocation = locations.filter(loc => loc._id == tenant.location_id)[0]
            setLocation(tenantLocation)
        }
        
    }, [tid, tenant, organization, editForm, tenantChanged, location]);

    function useCompare(val) {
        const prevVal = usePrevious(val)
        return prevVal !== val
    }

    function usePrevious(value) {
        const ref = useRef();
        useEffect(() => {
            ref.current = value;
        });
        return ref.current;
    }

    const updateForm = (key, value, nestedKey) => {
        if (nestedKey) {
            const nested = editForm[nestedKey]
            nested[key] = value
            setEditForm({...editForm, [nestedKey]: nested})
        } else {
            setEditForm({...editForm, [key]: value})
        }
    }

    const editTenant = (e) => {
        e.preventDefault()

        if(tenant?.phones && tenant.phones.length !== 0) tenant.phone = tenant.phones[0].number
        setIsEditing(true);
        setEditForm(tenant)
    }


    

    const datePicker = ({in_date = null, out_date = null}) => {
        Swal.fire({
            allowOutsideClick: true,
            icon:"success",
            backdrop: true,
            width: "60%",
            title: (in_date)? "Einzug Datum":"Auszug Datum",
            html: 
            `<div class="form-group">` +
            `  <input type="date" id="inputDate" class="form-control form-control-lg mb-1" />`+
            `</div>` ,
            showConfirmButton: true,
            showCancelButton: true,
            confirmButtonText: `Speichen`,
            customClass:{
                icon:"text-justify",
            },
            preConfirm: () => {
                return Swal.getPopup().querySelector('#inputDate').value
            } 
        })
        .then(async results => {
            if(results.isConfirmed){
                let updatedTenant = {...tenant}
                if(!!in_date) {
                    setInDate(results.value)
                    updateForm('in_date', results.value)
                    //updatedTenant.in_date = new Date(results.value)
                }
                if(!!out_date){
                    setOutDate(results.value)
                    updateForm('out_date', results.value)
                    //updatedTenant.out_date = new Date(results.value)
                }

               
                //updateTenant(updatedTenant).then().catch()
            }
        })
    }

    const OverviewTabContent = () => {

        if (!tenant) return (<Fragment></Fragment>);

        const saveTenantDetails = async () => {
            if(hasPermission({user, scopes:['tenant.update.own', 'tenant.update.any']})) {
                let params = editForm;
                if(tenant.phones && tenant.phones.length <=0) {
                    let numObj = {"type": "work", "primary": true, "number": ""};
                    numObj.number = params.phone;
                    params.phones[0] = numObj;
                }
                if(tenant.phones && tenant.phones[0]) {
                    params.phones[0].number = params.phone;
                }

                updateTenant(params).then().catch().finally(() => {setIsEditing(false)});
            }
        }

        const RenderConditionalButtons = () => {
            if (isEditing === true) {
                return (
                    <div className="col-12">
                        <button className="btn btn-primary mt-1 me-1 waves-effect waves-float waves-light" onClick={(e) => {e.preventDefault();saveTenantDetails()}}>Speichern
                        </button>
                        <button className="btn btn-muted mt-1 me-1 waves-effect waves-float waves-light"
                                onClick={(e) => {
                                    e.preventDefault();
                                    setIsEditing(false);
                                }}>Abbrechen
                        </button>
                    </div>
                )
            } else {
                if (!!user) {
                    return (
                        <PermissionsWrapper
                            user={user}
                            scopes={['tenant.update.any']}
                        >
                            <div className="col-12">
                                <button id="tenantdetailsUpdateBtn" className="btn btn-secondary mt-1 me-1 waves-effect waves-float waves-light" onClick={(e) => {editTenant(e)}}>Anpassen
                                </button>
                            </div>
                        </PermissionsWrapper>
                    )
                }
            }
            return (<Fragment></Fragment>);
        }

        const inputAddress = () => {
            if(isEditing){
                const curLoc = locations.filter(loc => loc._id === tenant.location_id)
                let orderedLocs = [...locations]
                // don't exclude multi premise if tenant is associated to multi premise right now
                if(!!curLoc[0] && curLoc[0]?.type !== "multi_premise") orderedLocs = orderedLocs.filter(loc => loc.type !== "multi_premise")
                orderedLocs.sort(function (a, b) {
                    if (a.address.street < b.address.street) {
                    return -1;
                    }
                    if (a.address.street > b.address.street) {
                    return 1;
                    }
                    return 0;
                });
                return (
                    <Fragment>
                        <label className="form-label" htmlFor="first_name">Mieteinheit (Straße, Hausnr., Mieteinheit, Plz, Ort)</label>
                        <select className="select2 form-select" name="tnt_location_id" id="tnt_location_id" onChange={(e) => {updateForm('location_id', e.target.value)}} disabled={!isEditing}>
                            {
                                orderedLocs.map(loc => {
                                    return (
                                        <option key={loc._id} value={loc._id} selected={isEditing ? editForm?.location_id === loc._id : tenant.location_id === loc._id}>
                                            {CapitalizeUtils.capitalize(loc.address.street)} {loc.address.house_number}, {'ME ' + CapitalizeUtils.capitalize(loc.address.street1)}, {loc.address.post_code} {CapitalizeUtils.capitalize(loc.address.city)}
                                        </option>
                                    )
                                })
                            }
                        </select>
                    </Fragment>
                )
            }else{
                return (
                    <Fragment>
                        <label className="form-label" htmlFor="first_name">Mieteinheit (Straße, Hausnr., Mieteinheit, Plz, Ort)</label>
                        <div className="input-group">
                            <input type="text" className="form-control" value={ (!!location && location?.address)?`${CapitalizeUtils.capitalize(location.address.street)} ${location.address.house_number}, ${'ME ' + CapitalizeUtils.capitalize(location.address.street1)}, ${location.address.post_code} ${CapitalizeUtils.capitalize(location.address.city)}` : ''} disabled/>
                            <Link to={`/details/location/${location._id}`}><a ><button className="btn btn-primary waves-effect" type="button"><i class="fa-solid fa-house"></i></button></a></Link>
                        </div>
                    </Fragment>
                )
            }
        
        }

        return (
            <Fragment>
                <div className="card">
                    <div className="card-header border-bottom">
                        <div style={{display: "flex", flex: "1 1 auto"}}>
                            <div style={{flex: 1}}>
                                <h4 className="card-title">Details der Nutzer </h4>
                            </div>
                        </div>
                    </div>
                    <div className="card-body" id="tenantsDetailsData">
                        <form className="validate-form mt-2 pt-50" noValidate="noValidate"  id="frmTenantsDetails">
                            <div className="row">
                                <div className="col-12 col-sm-6 mb-1">
                                    <label className="form-label" htmlFor="tenant_id">ID</label>
                                    <input type="text" className="form-control" id="frm_tenant_id" name="frm_tenant_id" onChange={(e) => { updateForm('first_name', e.target.value) }} value={isEditing ? editForm.model : tenant?._id} disabled/>
                                </div>
                                <div className="col-12 col-sm-6 mb-1">
                                    <label className="form-label" htmlFor="user_id">User ID</label>
                                    <input type="text" className="form-control" id="frm_user_id" name="frm_user_id" onChange={(e) => { updateForm('user_id', e.target.value) }} value={isEditing ? editForm.model : tenant?.user_id} disabled/>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12 col-sm-6 mb-1">
                                    <label className="form-label" htmlFor="first_name">Vorname*</label>
                                    <input type="text" className="form-control" maxlength="20" id="first_name" name="first_name" onChange={(e) => { updateForm('first_name', e.target.value) }} value={isEditing ? editForm.model : CapitalizeUtils.capitalize(tenant?.first_name)} disabled={!isEditing}/>
                                </div>
                                <div className="col-12 col-sm-6 mb-1">
                                    <label className="form-label" htmlFor="last_name">Nachname*</label>
                                    <input type="text" className="form-control" maxlength="20" id="last_name" name="last_name" onChange={(e) => { updateForm('last_name', e.target.value) }} value={isEditing ? editForm.model : CapitalizeUtils.capitalize(tenant?.last_name)} disabled={!isEditing}/>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12 col-sm-6 mb-1">
                                    <label className="form-label" htmlFor="last_name">Email</label>
                                    <div className="input-group">
                                        <input type="email" className="form-control" maxlength="50" id="email" name="email" onChange={(e) => { updateForm('email', e.target.value) }} value={isEditing ? editForm.model : tenant?.email} disabled={!isEditing}/>
                                        <a href={`mailto:${tenant.email}`}><button className="btn btn-primary waves-effect" type="button"><i class="fa-solid fa-paper-plane-top"></i></button></a>
                                    </div>
                                </div>
                                <div className="col-12 col-sm-6 mb-1">
                                    <label className="form-label" htmlFor="tenantPhoneNumber">Telefonnummer</label>
                                    <input type="text" className="form-control" maxlength="20" id="tenantPhoneNumber" name="tenantPhoneNumber" placeholder="Telefonnummer" onChange={(e)=>updateForm('phone', e.target.value)} value={isEditing ? editForm.model : (tenant?.phones && tenant?.phones[0]?.number ? tenant?.phones[0]?.number : ''  )} disabled={!isEditing} />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12 col-sm-6 mb-1">
                                    <label className="form-label" htmlFor="last_name">Einzug</label>
                                    <div className="input-group">
                                        <input type="text"  className="form-control" maxlength="20" id="in_date" name="in_date" value={isEditing ? inDate : formatDate(tenant?.in_date)} disabled/>
                                        {
                                            isEditing ?
                                            <button className="btn btn-primary waves-effect" type="button" onClick={() => {datePicker({in_date: true})}}><i class="fa-regular fa-calendar-days"></i></button>
                                            : ''
                                        }
                                    </div>
                                </div>
                                <div className="col-12 col-sm-6 mb-1">
                                    <label className="form-label" htmlFor="last_name">Auszug</label>
                                    <div className="input-group">
                                        <input type="text"  className="form-control" maxlength="20" id="out_date" name="out_date" value={isEditing ? outDate : formatDate(tenant?.out_date)} disabled/>
                                        {
                                            isEditing ?
                                            <button className="btn btn-primary waves-effect" type="button" onClick={() => {datePicker({out_date: true})}}><i class="fa-regular fa-calendar-days"></i></button>
                                            : ''
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12 col-sm-6 mb-1">

                                    {inputAddress()}

                                </div>
                                <div className="col-12 col-sm-6 mb-1">
                                    <label className="form-label" htmlFor="organization">Kunde</label>
                                    <input type="text"  className="form-control" id="organization" name="organization" value={ (!!tenant && !!tenant.organization)? tenant.organization.name : ''} disabled/>
                                </div>
                            </div>
                            {RenderConditionalButtons()}
                        </form>
                    </div>
                </div>
            </Fragment>
        )
    }

    const UI = () => {
        if (tenant) {
            return (
                <Fragment>

                    <div className="content-header row">
                        <div className="content-header-left col-md-9 col-12 mb-2">
                            <div className="row breadcrumbs-top">
                                <div className="col-9">
                                    <h2 className="content-header-title float-start mb-0">Nutzerdetails </h2>
                                    <div className="breadcrumb-wrapper">
                                        <ol className="breadcrumb">
                                            <li className="breadcrumb-item"><a>Apps</a></li>
                                            <li className="breadcrumb-item">
                                                <Link className="breadcrumb-navLink" to="/apps/tenants"><a>Nutzer</a></Link>
                                            </li>
                                            <li className="breadcrumb-item active">Details
                                            </li>
                                            <li>
                                                <span className="tourBorderRight" />
                                                <PageTour steps={TourSteps.tenants.details}  isButton={false} />
                                            </li>
                                        </ol>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <OrgCookieCrumb/>
                    </div>
                    <section id="tenantTabs">
                        <div className="row">
                            <div className="col-12">
                                <ul className="nav nav-pills mb-2">
                                    <li className="nav-item">
                                        <a className={`nav-link ${(tabActive === 'overview') ? 'active' : ''}`} onClick={() => { setTabActive('overview')}}>
                                            <i class="fa-solid fa-magnifying-glass"></i>
                                            <span className="fw-bold">Details</span>
                                        </a>
                                    </li>
                                </ul>

                            </div>
                        </div>
                    </section>

                    <section id="tabpanels">
                        <div className="row">
                            <div className="col-12">
                                <div className="tab-content pt-1">
                                    <div className={`tab-pane ${(tabActive === 'overview') ? 'active' : ''}`} id="logs" role="tabpanel" aria-labelledby="home-tab-fill">
                                        {OverviewTabContent()}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                </Fragment>
            )
        }

    }

    return (
        <Fragment>
            {UI()}
        </Fragment>
    )


}

TenantDetails.requiredScope = ['tenant.view.own', 'tenant.view.any'];
TenantDetails.requireAuth = true;  // true -> force login via AuthContext, false -> no login required so no user available either
export default TenantDetails;