import React, { Fragment, useState, useEffect, useContext, useRef } from 'react';
import { MgmtUsersContext } from '../../../../context/Users/UsersContext';
import { AuthContext } from '../../../../context/Auth/AuthProvider';

import Swal from '@molline/sweetalert2';
import { useRouter } from "../../../Shared/Router/Router"
import {OrganizationContext} from '../../../../context/Organization/Organization';

import de from "../datatable.de.json";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import {PermissionsWrapper, hasPermission} from "../../reactPermissionsWrapper";

import PageTour from "../../../../components/Dashboard/tour/tour";
import TourSteps from "../../../../TourSteps.json";
import capitalizeUtil from "../../../../utils/capitalizeUtils";



const $ = require('jquery');
$.DataTable = require('datatables.net');

const TblUsers = (props) => {

    const router = useRouter()
    const { user } = useContext(AuthContext);
    const {organization, organizations} = useContext(OrganizationContext)
    const {
        roles,
        scopes,
        deactivateUser,
        changeRoles,
        createUser,
        logOutTarget
    } = useContext(MgmtUsersContext);

    const usersChanged = useCompare(props.users)
    const organizationsChanged = useCompare(organizations)

    const rolesChanged = useCompare(roles)
    const scopesChanged = useCompare(scopes)

    useEffect(() => {

        if (usersChanged === true || rolesChanged) {
            initializeDT();
        }

    }, [props, user, usersChanged, organization, organizations, organizationsChanged, organizations, roles, rolesChanged, scopes, scopesChanged]);

    function useCompare(val) {
        const prevVal = usePrevious(val)
        return prevVal !== val
    }

    function usePrevious(value) {
        const ref = useRef();
        useEffect(() => {
            ref.current = value;
        });
        return ref.current;
    }

    async function changeRolesFor(userId) {
        await changeRoles({userId});
    }

    const initializeDT = () => {
        if (!!props.users && props.users.length > 0) {
            if ($.fn.dataTable.isDataTable(`#${props.id}`)) {
                $(`#${props.id}`).DataTable().destroy();
                // remove the eventhandler to prevent multiple events
                $(`#${props.id} tbody`).off('click')
                $(`#${props.id} tbody`).off('mouseenter')
                $(`#${props.id} tbody`).off('mouseleave')
                initializeDT()
            }else{
                $(`#${props.id}`).DataTable({
                    "language": de,
                    paging: true,
                    "pagingType": "full",
                    processing: true,
                    searching: true,
                    data: props.users,
                    orderCellsTop: true,
                    fixedHeader: true,
                    "rowId": "_id",
                    "pageLength": 50,
                    "columns": [
                        { 
                            data: null,
                            visible: false,
                            searchable: false,
                            render:function(data, type, row) {
                                if(!!data.socket && data.socket.status === 'connected') return `<i class="fa-solid fa-circle text-success"></i>`
                                return `<i class="fa-solid fa-circle text-danger"></i>`
                            }
                        },
                        { 
                            data: null,
                            visible: false,
                            searchable: true,
                            render:function(data, type, row) {
                                return data._id.toString();
                            }
                        },
                        { 
                            data: null,
                            searchable: true, 
                            render:function(data, type, row) {
                                return capitalizeUtil.capitalize(data.first_name);
                            }
                        },
                        { 
                            data: null,
                            searchable: true, 
                            render:function(data, type, row) {
                                return capitalizeUtil.capitalize(data.last_name);
                            }
                        },
                        { 
                            data: null,
                            searchable: true,
                            render:function(data, type, row) {
                                if(!!data.emails && !!data.emails[0]) return `<a href="mailto:${data.emails[0].email}">${data.emails[0].email}</a>`;
                                return ''
                            }
                        },
                        {
                            data: null,
                            "searchable": true,
                            "ordering": false,
                            render:function(data, type, row) {
                                if(data.roles.length > 0) {
                                    return data.roles.map(role => {
                                        return `<span class="badge rounded-pill bg-primary me-25 mb-25" style="font-size:1em">${role}</span>`
                                    })
                                }
                                return ''
                            }
                        },
                        {
                            data: null,
                            "searchable": true,
                            "ordering": false,
                            "className": "text-end",
                            render:function(data, type, row) {
                                if(hasPermission({user, scopes:['user.update.own', 'user.update.any', 'user.delete.any', 'profile.view.any']})){
                                    return `
                                    <div class="dropdown b-dropdown btn-group" id="${data._id.toString()}">
                                        <button aria-haspopup="true" type="button" data-bs-toggle="dropdown" id="${props.id}Dropdownbtns" class="btnOverflowMenu btn btn-sm dropdown-toggle btn-secondary dropdown-toggle-split" >
                                            <i class="fa-solid fa-bars"></i>
                                        </button>
                                        <ul role="menu" tabindex="-1" class="dropdown-menu dropdown-menu-right" >
                                            <li role="presentation" class="viewUser">
                                                <a role="menuitem" class="dropdown-item"><i class="text-primary fa-solid fa-magnifying-glass me-25"></i> Details</a>
                                            </li>
                                            ${
                                                (hasPermission({ user, scopes: ['role.grant.any', 'role.grant.own', 'role.remove.own', 'role.remove.any']})) ?
                                                `<li role="presentation">
                                                    <hr role="separator" aria-orientation="horizontal" class="dropdown-divider">
                                                </li>
                                                <li role="presentation" class="roleChange">
                                                    <a role="menuitem" class="dropdown-item"><i class="text-secondary fa-solid fa-lock-open me-25"></i> Rollen ändern</a>
                                                </li>` : ''
                                            }
                                            ${
                                                (hasPermission({ user, scopes: ['user.update.any', 'user.update.own']})) ?
                                                `<li role="presentation">
                                                    <hr role="separator" aria-orientation="horizontal" class="dropdown-divider">
                                                </li>
                                                <li role="presentation" class="delUser">
                                                    <a role="menuitem" class="dropdown-item"><i class="text-danger fa-solid fa-trash me-25"></i> Löschen</a>
                                                </li>` : ''
                                            }
                                            ${
                                                (hasPermission({ user, scopes: ['user.update.any']})) ?
                                                `<li role="presentation">
                                                    <hr role="separator" aria-orientation="horizontal" class="dropdown-divider">
                                                </li>
                                                <li role="presentation" class="targetLogout">
                                                    <a role="menuitem" class="dropdown-item alert-danger"><i class="text-danger fa-solid fa-right-from-bracket me-25"></i> Target Logout</a>
                                                </li>` : ''
                                            }
                                        </ul>
                                    </div>`
                                }
                            }
                        }
                    ],
                    createdRow: function (row, data, dataIndex) {
                        $(row).attr('data-user_id', data._id || 'na');
                    },
                    "fnDrawCallback": function( oSettings ) {
                        
                    }
                });
                $('.dataTables_length select').addClass('form-select');
                $('.dataTables_filter input').addClass('form-control');
                $(`#${props.id} tbody`).on('mouseenter', 'tr', function () {
                    //$(this).css('cursor', 'pointer');
                    $(this).css('background-color', '#f3f2f7');
                    $(this).css('cursor', 'pointer');
                });

                $(`#${props.id} tbody`).on('mouseleave', 'tr', function () {
                    $(this).css('background-color', 'transparent');
                });

                $(`#${props.id} tbody`).on('click', 'tr', function () {
                    const selected = document.querySelectorAll('.selected');
                    for (let i = 0; i < selected.length; i++) { selected[i].classList.remove('selected') }
                    $(this).toggleClass('selected');
                });
                $(`#${props.id} tbody`).on('click', 'li.viewUser', function (e) {
                    //e.preventDefault()
                    let link = e.currentTarget;
                    let parent = link.closest('tr');
                    //hideMenus();
                    router.push(`/details/user/${parent.id}`);
                });
                $(`#${props.id} tbody`).on('click', 'li.roleChange', function (e) {
                    //e.preventDefault()
                    let link = e.currentTarget;
                    let parent = link.closest('tr');
                    changeRolesFor(parent.id);
                    

                });
                $(`#${props.id} tbody`).on('click', 'li.delUser', function (e) {
                    //e.preventDefault()
                    let link = e.currentTarget;
                    let parent = link.closest('tr');
                    deactivateUser({user_id:parent?.id}).then(()=>{});
                });

                $(`#${props.id} tbody`).on('click', 'li.targetLogout', function (e) {
                    //e.preventDefault()
                    let link = e.currentTarget;
                    let parent = link.closest('tr');
                    logOutTarget(parent.id).then(()=>{});
                });
            }
        }
    }

    const addUserForm = () => {
        let html = '<input id="frm_first_name" type="text" class="form-control form-control-lg mb-1" placeholder="Vorname *" required />' +
            '<input id="frm_last_name" type="text" class="form-control form-control-lg mb-1" placeholder="Nachname *" required />' +
            '<input id="frm_email" type="email" class="form-control form-control-lg mb-1" placeholder="E-Mail *" required />';
        // A user with super admin can add an user for any organization in the system.
        if(hasPermission({ user, scopes:['organization.view.any'] })) {
            html += '<div class="input-group"><span class="input-group-text"><i class="fa-solid fa-people-group"></i></span><select name="orgs" id="frm_org" class="form-select  form-select-lg" >'
            organizations.map(org => {
                html += `<option ${(org._id === organization._id) ? 'selected' : ''} value="${org._id}">${org.name}</option>`;
            })
            html += '</select></div>';
        }

        Swal.fire({
            title: 'Neuen Benutzer anlegen',
            iconHtml: '<i class="fa-solid fa-user"/>',
            customClass: {
                icon:"border-0"
            },
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            showConfirmButton: true,
            confirmButtonText: "Benutzer anlegen",
            cancelButtonText: "Abbrechen",
            showCancelButton: true,
            width: '50rem',
            html: html,

            preConfirm: () => {
                const first_name = Swal.getPopup().querySelector('#frm_first_name').value
                const last_name = Swal.getPopup().querySelector('#frm_last_name').value
                const email = Swal.getPopup().querySelector('#frm_email').value
                let organization_id = null;
                if (Swal.getPopup().querySelector('#frm_org') !== null) {
                    organization_id = Swal.getPopup().querySelector('#frm_org').value;
                }
                if (user && !user.roles.includes('god')) {
                    organization_id = user.organization_id;
                }
                if (!first_name || !last_name || !email) {
                    Swal.showValidationMessage(`Alle Felder sind Pflichtfelder`)
                }
                return {
                    first_name,
                    last_name,
                    email,
                    organization_id
                }
            }
        })
            .then(async results => {
                if (!results.isConfirmed || results.isDenied || results.isDismissed) return;
                try{ await createUser(results.value) }catch(err){}
            })
    }

    const UI = () => {
        const isProcessing = () => {
            if (props.users.length === 0 && !usersChanged) {
                return (
                    <div className="mt-4">
                        <h4 className="text-center"><i class="fa-solid fa-cog fa-spin"></i> WIRD AKTUALISIERT ...</h4>
                    </div>
                )
            }
        }
        return (
            <Fragment>
                <div className="card">
                    <div className="card-header d-flex justify-content-between align-items-center">
                        <h4 className="card-title">Benutzerliste </h4>
                        <PermissionsWrapper 
                            scopes={['user.invite.any', 'user.invite.own']} 
                            user={user} 
                        >
                            <div className="heading-elements">
                                <div className="heading-elements">
                                    <button className="btn btn-primary " id="userCreateBtn" onClick={() => { addUserForm() }} ><FontAwesomeIcon icon={faPlus} /></button>
                                </div>
                            </div>
                        </PermissionsWrapper>
                    </div>
                    <div className="card-body table-responsive">
                        <div className='table-responsive'>
                            <table id={props.id} className="table mb-0 row-border hover order-column w-100" role="grid">
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th></th>
                                        <th>VORNAME</th>
                                        <th>NAME</th>
                                        <th>EMAIL</th>
                                        <th>ROLLEN</th>
                                        <th></th>
                                    </tr>
                                </thead>
                            </table>
                        </div>
                        {isProcessing()}
                    </div>
                </div>
            </Fragment>
        )
    }

    return (
        <Fragment>{UI()}</Fragment>
    )



}
export default TblUsers;