import React, {Fragment, useEffect, useContext } from 'react';
import {SensorsContext} from "../../../../context/Devices/Sensors";
import { CSVLink, CSVDownload } from "react-csv";

const SensorCoverageWidget = (props) => {

    const { sensors, sensorsChanged, sanitizedSensor, roundDecimal } = useContext(SensorsContext);

    useEffect(  () => {
            
    }, [sensors, sensorsChanged ]);

    const UI = () => {
        let count = 0;
        let date = new Date()
        let wmbus = sensors.filter(sensor => sensor.attributes.includes('wmbus'));
        let sensorArray = []

        for(let sensor of wmbus){
            if(sensor.state === undefined || sensor.state.date === undefined){
                sensorArray.push(sanitizedSensor(sensor)); //Add bad sensor to report
                continue;
            }
            let sDate = new Date(sensor.state.date)
            let hours = Math.abs(date - sDate) / 36e5;
            if(hours < 360){
                count ++
            }else{
                sensorArray.push(sanitizedSensor(sensor)); //Add bad sensor to report
            }
        }
        let percent = ( (count / wmbus.length) * 100).toFixed(1)
        percent = roundDecimal(percent)
        if(isNaN(percent)) percent = 0;

        if(wmbus.length >0) {

            if(percent < 100){
                return (
                    <Fragment>
                        <div className="col-xl-2 col-md-4" id="widgetSensorCoverage">
                            <CSVLink
                                data={sensorArray}
                                filename={"poor-sensor-report.csv"}
                            >
                                <div className="card text-center " title="Prozentsatz der Zähler, die innerhalb von 15 Tagen Daten gesendet haben">
                                    <span className="badge rounded-pill bg-info fa-fade badge-up" id="sensorCoverageDownloadIcon"><i class="fa-solid fa-download"></i></span>
                                    <div className="card-body align-self-center">
                                        <div className="mx-auto my-1" style={{display: "inline-flex", alignItems: "center"}}>
                                            <i className={`fa-solid fa-signal-stream fa-4x text-${(+percent < 96)? 'warning':'success'}`}></i>
                                        </div>
                                        <p className="mb-0 text-muted">Abdeckung Funk</p>
                                        <h2 className="mb-0 line-ellipsis ">{percent}%</h2>
                                    </div>
                                </div>
                            </CSVLink>
                        </div>
                    </Fragment>
                )
            }

            return (
                <Fragment>
                    <div className="col-xl-2 col-md-4" id="sensorNotFullCoverageCounter">
                        <div className="card text-center " title="Prozentsatz der Zähler, die innerhalb von 15 Tagen Daten gesendet haben">
                            <div className="card-body align-self-center">
                                <div className="mx-auto my-1" style={{display: "inline-flex", alignItems: "center"}}>
                                    <i className={`fa-solid fa-signal-stream fa-4x text-${(+percent < 96)? 'warning':'success'}`}></i>
                                </div>
                                <p className="mb-0 text-muted">Abdeckung Funk</p>
                                <h2 className="mb-0 line-ellipsis ">{percent}%</h2>
                            </div>
                        </div>
                    </div>
                </Fragment>
            )
        }

        
    }

    return (
        <Fragment>
            {UI()}
        </Fragment>
    )

}

export default SensorCoverageWidget;