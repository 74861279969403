import React, {useState, useEffect, createContext, Fragment, useContext, useRef } from 'react';
import Axios_client from "../../utils/axios_client";
import {AuthContext} from '../Auth/AuthProvider';
import {GroupsContext} from "../Groups/Groups";
import {TenantContext} from "../Tenant/Tenant";
import {useRouter} from "../../components/Shared/Router/Router";

import {MgmtUsersContext} from '../Users/UsersContext'
import {PermissionsWrapper, hasPermission} from "../../components/Dashboard/reactPermissionsWrapper";
import {OrganizationContext} from '../Organization/Organization';
import Swal from "@molline/sweetalert2";
import Cookie from "js-cookie";
import capitalizeUtil from "../../utils/capitalizeUtils";

export const LocationsContext = createContext();

/**
 * Locations are buildings or addresses which are available for management.
 * These shall be fetched int he background, based upon the user that is logged in.
 */
const LocationsProvider = ({children, context}) => {

    const router = useRouter()
    const { user } = useContext(AuthContext);
    const { group, setGroup, getGroups, groups, setGroups } = useContext(GroupsContext);
    const { organization, organizations } = useContext(OrganizationContext);
    const { getAllUsers } = useContext(MgmtUsersContext)
    const [location, setLocation] = useState({});
    const [locations, setLocations] = useState([]);
    const [searchLocations, setSearchLocations] = useState([]);

    const [groupLocations, setGroupLocations] = useState([]);
    //const { getCurrentTenant, currTenant} = useContext(TenantContext);

    const userChanged = useCompare(user)
    const groupChanged = useCompare(group)
    const organizationChanged = useCompare(organization)
    const locationsChanged = useCompare(locations)
    const locationChanged = useCompare(location)
    const searchLocationsChanged = useCompare(searchLocations)


    const [locationTypes, setLocationTypes] = useState([
        "multi_premise",
        "single_premise"
    ])

    useEffect( () => {

        if( userChanged === true || organizationChanged === true || groupChanged === true ){
            getAllLocations({filter:true}).then().catch();
        }

        if( groupChanged === true && locations.length > 0){
            
        }

    }, [user, userChanged, locations, location, groupChanged, group, groups, organizationChanged, organization, organizations])


    function useCompare (val) {
        const prevVal = usePrevious(val)
        return prevVal !== val
    }

    function usePrevious(value) {
        const ref = useRef();
        useEffect(() => {
            ref.current = value;
        });
        return ref.current;
    }

    const errorToast = (err) => {
        if(!err) return
        Swal.fire({
            toast: true,
            position: 'bottom-end',
            timer: 3000,
            timerProgressBar: true,
            allowOutsideClick: true,
            text: err,
            showConfirmButton: false,
            customClass: {
                popup: "bg-danger text-white"
            }
        })
    }

    const successToast = (msg) => {
        if(!msg) return
        Swal.fire({
            toast: true,
            position: 'bottom-end',
            timer: 3000,
            timerProgressBar: true,
            allowOutsideClick: true,
            text: msg,
            showConfirmButton: false,
            customClass: {
                popup: "bg-success text-white"
            }
        })
    }

    const doYouReallyWantToSave = () => {
        return new Promise(async (resolve) => {
            const answer = await Swal.fire({
                title: 'Sicher?',
                text: 'Damit werden die Daten gespeichert',
                showCancelButton: true,
                confirmButtonText: 'Ja',
                cancelButtonText: "Nein",
            })
            if (!answer.isConfirmed) return resolve(false);
            return resolve(true);
        })
    }
    /**
     * The current code allows for rendering inside dataTables
     * To render the same html inside of JSX you must use:
     * <span dangerouslySetInnerHTML={{__html:locationIcon({location, size:6})}}></span>
     */
    const locationIcon = ({location, size = 1}) => {
        if(location.type === 'multi_premise') return `<i class="text-primary fa-solid fa-building fa-${size}x"></i>`
        if(location.type === 'single_premise') return `<i class="text-info fa-solid fa-house fa-${size}x"></i>`
        return `<i class="text-danger fa-regular fa-building-circle-xmark fa-${size}x"></i>`
    }
    /**
     * Location is filtered by the requesting user in the api.location
     */
    const getLocation = (id) => {
        return new Promise(async (resolve, reject) => {
            if( user && hasPermission({user, scopes:['location.view.any', 'location.view.own']}) ){
                Axios_client.backendClient().get(`${process.env.REACT_APP_API_OPENMETER}/location?_id=${id}`, {})
                .then( response => {
                    setLocation(response.data)
                    return resolve(response.data);
                })
                .catch( err => {
                    console.log('LOCATION FETCHING ERROR 36', err.message)
                    return reject(err);
                })
            }else{
                return reject()
            }
        })
    }

    const searchLocationsByAddress = (searchKey) => {
        return new Promise(async (resolve, reject) => {
            if( user && hasPermission({user, scopes:['location.view.any', 'location.view.own']}) ){
                Axios_client.backendClient().get(`${process.env.REACT_APP_API_OPENMETER}/location/search?searchKey=${searchKey}&realm=molline`, {})
                    .then( response => {
                        setSearchLocations(response.data)
                        return resolve(response.data);
                    })
                    .catch( err => {
                        console.log('LOCATIONS SEARCH ERROR 159', err.message)
                        return reject(err);
                    })
            }else{
                return reject()
            }
        })
    }

    /**
     * Allow the request of locations from an organization
     * but do not set the context when requested.
     * Context shall only be set for a user whom is WITHIN an orgainzational
     * context
     */
    const getAllLocations = ({organization_id = null, filter = true}) => {
        return new Promise( async (resolve, reject) => {
            if(!organization._id && !organization_id){
                setLocations([]);
                return [];
            }
            let param = (organization_id)? organization_id : organization._id
            if( user && hasPermission({user, scopes:['location.view.any', 'location.view.own']}) ){
                Axios_client.backendClient().get(`${process.env.REACT_APP_API_OPENMETER}/location/all?organization_id=${param}`, {})
                .then( response => {
                    if(!filter) return resolve(response.data)
                    let filterArray = [...response.data];

                    if(!!group && group?.name) filterArray = response.data.filter(loc => group.members.includes(loc._id))
                    let orderedLocs = [...filterArray]
                    orderedLocs.sort(function (a, b) {
                        const bLocString = `${b.address.street} ${a.address.house_number} ${b.address.street1 === "" ? "" : b.address.street1}`
                        const aLocString = `${a.address.street} ${b.address.house_number} ${a.address.street1 === "" ? "" : a.address.street1}`
                        return aLocString.localeCompare(bLocString, 'de', {
                            numeric: true
                        })
                    });
                    setLocations(orderedLocs);
                    if(filterArray[0] !==undefined) setLocation(filterArray[0])
                    return resolve(filterArray);
                })
                .catch( err => {
                    console.log('LOCATION FETCHING ERROR 39', err.message)
                    return reject(err)
                })
            }
        })
    }
    /**
     * Send the new updated location to the database
     */
    const locationUpdate = (newLocation) => {
        return new Promise( async (resolve, reject) => {
            if( user && hasPermission({user, scopes:['location.update.any', 'location.update.own']}) ){
                Axios_client.backendClient().put(`${process.env.REACT_APP_API_OPENMETER}/location`, newLocation)
                .then(response => {
                    setLocation(response.data)
                    successToast('Nutzer-/Mieteinheit wurde erfolgreich bearbeitet')
                    getAllLocations({filter:true}).then().catch();
                })
                .catch( error => {
                    errorToast('Fehler beim Ändern der Nutzer-/Mieteinheit')
                    reject(error)
                })
            }
        })
    }

    const deleteLocation = async (target) => {
        const answer = await Swal.fire({
            title: 'Sicher löschen?',
            text: 'Damit werden die Daten gelöscht',
            showCancelButton: true,
            confirmButtonText: 'Ja',
            cancelButtonText: "Nein",
        })
        if(!answer.isConfirmed) return;

        if(Object.keys(target.consumption).length > 0) {
            errorToast('Diese Nutzer-/Mieteinheit hat Varbrauchswerte und kann deshalb nicht gelöscht werden. Bitte kontaktieren Sie einen Administrator.')
            return;
        }
     
        if(target.tenants.length > 0){
            errorToast('Dieser Nutzer-/Mieteinheit ist aktuell ein Nutzer zugewiesen und kann deshalb nicht gelöscht werden. Bitte kontaktieren Sie einen Administrator.')
            return;
        }

        Axios_client.backendClient().delete(`${process.env.REACT_APP_API_OPENMETER}/location`, {data: {_id: target._id}})
        .then(async response => {
            successToast('Nutzer-/Mieteinheit wurde erfolgreich bearbeitet')
            await getAllLocations({filter:true});
            router.push('/apps/locations')
        })
        .catch( error => {
            errorToast('Fehler beim Bearbeiten der Nutzer-/Mieteinheit')
            return;
        })
    }

    const addChildLocation = ({target_id, child_id}) => {
        return new Promise( (resolve, reject) => {
            if( user && hasPermission({user, scopes:['location.update.any', 'location.update.own']}) ){
                Axios_client.backendClient().put(`${process.env.REACT_APP_API_OPENMETER}/location/child`, {target_id, child_id})
                .then(response => {
                    let curLocation = {...location}
                    locations.map(loc => {
                        if(loc._id === child_id){
                            curLocation.children.push(loc._id);
                        }
                    })
                    setLocation(curLocation)
                    getAllLocations({filter:true}).then().catch();
                    return resolve()
                })
                .catch( error => {
                    console.log('ADD SIBLING ERROR', error)
                    errorToast('Fehler beim Hinzufügen der Beziehung')
                    return reject(error)
                })
            }
        })
    }

    const removeChildLocation = async ({target_id, child_id}) => {
        const answer = await Swal.fire({
            title: 'Sicher löschen?',
            text: 'Damit wird die Beziehung gelöscht',
            showCancelButton: true,
            confirmButtonText: 'Ja',
            cancelButtonText: "Nein",
        })
        if(!answer.isConfirmed) return;

        return new Promise( (resolve, reject) => {
            if( user && hasPermission({user, scopes:['location.update.any', 'location.update.own']}) ){
                Axios_client.backendClient().delete(`${process.env.REACT_APP_API_OPENMETER}/location/child`, { data: {target_id, child_id} })
                .then(response => {
                    let curLocation = {...location}
                    curLocation.children = location.children.filter(loc => loc !== child_id)
                    setLocation(curLocation)
                    getAllLocations({filter:true}).then().catch();
                    successToast('Die Beziehung wurde erfolgreich entfernt')
                    return resolve()
                })
                .catch( error => {
                    console.log('DELETE CHILD ERROR', error)
                    errorToast('Fehler beim Entfernen der Beziehung')
                    return reject(error)
                })
            }
        })
    }

    const addParentLocation = ({target_id, parent_id}) => {
        return new Promise( (resolve, reject) => {
            if( user && hasPermission({user, scopes:['location.update.any', 'location.update.own']}) ){
                Axios_client.backendClient().put(`${process.env.REACT_APP_API_OPENMETER}/location/parent`, {target_id, parent_id})
                .then(response => {
                    let curLocation = {...location}
                    const newParent = locations.filter(loc => loc._id === parent_id)
                    curLocation.parents.push(newParent[0]._id)
                    setLocation(curLocation)
                    getAllLocations({filter:true}).then().catch();
                    return resolve()
                })
                .catch( error => {
                    console.log('ADD PARENT ERROR', error)
                    errorToast('Fehler beim Hinzufügen der Beziehung')
                    return reject(error)
                })
            }
        })
    }

    const removeParentLocation = async ({target_id, parent_id}) => {
        const answer = await Swal.fire({
            title: 'Sicher löschen?',
            text: 'Damit wird die Beziehung entfernt',
            showCancelButton: true,
            confirmButtonText: 'Ja',
            cancelButtonText: "Nein",
        })
        if(!answer.isConfirmed) return;

        return new Promise( (resolve, reject) => {
            if( user && hasPermission({user, scopes:['location.update.any', 'location.update.own']}) ){
                Axios_client.backendClient().delete(`${process.env.REACT_APP_API_OPENMETER}/location/parent`, { data: {target_id, parent_id} })
                .then(response => {
                    let curLocation = {...location}
                    curLocation.parents = []
                    setLocation(curLocation)
                    getAllLocations({filter:true}).then().catch();
                    successToast('Die Beziehung wurde erfolgreich entfernt')
                    return resolve()
                })
                .catch( error => {
                    console.log('DELETE PARENT ERROR', error)
                    errorToast('Fehler beim Entfernen der Beziehung')
                    return reject(error)
                })
            }
        })
    }

    const getMonthlyConsumptionReport = ({location_id, monthIndex}) => {
        Axios_client.backendClient().get(`${process.env.REACT_APP_API_OPENMETER}/report/report/consumption?location_id=${location_id}&month=${monthIndex}&lang=de`)
        .then( response => {

            //     console.log(response.data)

        })
        .catch( err => {
            //         console.log('LOCATION REPORT FETCHING ERROR 203', err)
        })
    }

    const addComment = async (params) => {
        if( user && hasPermission({user, scopes:['location.update.any', 'location.update.own']}) ){
            try {
                const response = await Axios_client.backendClient().put(`${process.env.REACT_APP_API_OPENMETER}/location/comment`, params)
                getAllLocations({filter:true}).then().catch();;
                setLocation(response.data)
            } catch (e) {
                console.log('error at creating comment', e);
                throw new Error(e)
            }
        }
    }

    const deleteComment = async (params) => {
        if( user && hasPermission({user, scopes:['location.update.any', 'location.update.own']}) ){
            try {
                const response = await Axios_client.backendClient().delete(`${process.env.REACT_APP_API_OPENMETER}/location/comment`, { data: params })
                getAllLocations({filter:true}).then().catch();;
                setLocation(response.data)
            } catch (e) {
                console.log('error at deleting comment', e);
                throw new Error(e)
            }
        }
    }

    const createLocation = async (params) => {
        if( user && hasPermission({user, scopes:['location.create.any', 'location.create.own']}) ){
            try {
                if(!user || !user?.roles?.includes('god') && !user?.scopes?.find((v)=>v.indexOf("location.create") >= 0)) {
                    return;
                }
                const response =  await Axios_client.backendClient({
                    sessionCookie: Cookie.get('session'),
                    userCookie: Cookie.get('auth'),
                    app_key: Cookie.get('APP_KEY')

                }).post(`${process.env.REACT_APP_API_OPENMETER}/location` , params)
                setLocations([...locations, response.data])
            } catch (e) {
                console.log('error at creating location', e);
                throw new Error(e)
            }
        }
    }


    const capitalize = (str) => {
       return  capitalizeUtil.capitalize(str)
    }

    const getLocationAddress = () => {
        if (!location) return;
        return `${capitalizeUtil.capitalize(location?.address?.street)} ${location?.address?.house_number}, ${(location?.address?.street1) ? `ME ${location.address.street1}, ` : ''} ${location?.address?.post_code} ${capitalizeUtil.capitalize(location?.address?.city)}, ${location?.address?.country.toLocaleUpperCase()}`
    }

    const addLocationUser = async (location_id, user_id) => {
        try {
            const params = {
                "location_id": location_id,
                "user_id": user_id,
                "role": "bldg_manager"
            }

            const associateResponse = await Axios_client.backendClient({
                sessionCookie: Cookie.get('session'),
                userCookie: Cookie.get('auth'),
                app_key: Cookie.get('APP_KEY')
            }).put(`${process.env.REACT_APP_API_OPENMETER}/location/user`, params)
            setLocation(associateResponse.data)
            // get all users again or the roles might be incorrect in the table
            await getAllUsers()
            return associateResponse.data
        } catch (err) {
            console.log('error at adding user to location', err);
            throw new Error(err)
        }
    }

    const removeLocationUser = async (location_id, user_id) => {
        try {
            const body = {
                user_id: user_id,
                location_id: location_id
            }
            const response = await Axios_client.backendClient({
                sessionCookie: Cookie.get('session'),
                userCookie: Cookie.get('auth'),
                app_key: Cookie.get('APP_KEY')
            }).delete(`${process.env.REACT_APP_API_OPENMETER}/location/user` , { data: body })
            setLocation(response.data)
            return response.data
        } catch (err) {
            console.log('error at removing user from location', err);
            throw new Error(err)
        }
    }

    const toggleUviAttribute = async ({ locationToUpdate = null, type, shouldDownloadUvi = false, shouldGetUvi = false }) => {
        if (locationToUpdate === null) locationToUpdate = location
        let text = ''
        if(type === 'email') text = '<span>Wollen Sie wirklich zukünftig uVis an diese Nutzer-/Mieteinheit senden?</span>'
        if (type === 'email' && !shouldGetUvi) text = '<span>Wollen Sie wirklich zukünftig keine uVis mehr an diese Nutzer-/Mieteinheit senden?</span>'
        if(type === 'download') text = '<span>Wollen Sie wirklich zukünftig den Download von uVis für diese Nutzer-/Mieteinheit erlauben?</span>'
        if (type === 'download' && !shouldDownloadUvi) text = '<span>Wollen Sie wirklich zukünftig nicht mehr den Download von uVis für diese Nutzer-/Mieteinheit erlauben?</span>'


        text += '</br><small>Sollte es sich um eine Übergeordnete Nutzereinheit handeln, so werden alle untergeordneten Mieteinheiten auch aktualisiert</small>'
        const answer = await Swal.fire({
            title: 'Sicher?',
            html: text,
            showCancelButton: true,
            confirmButtonText: 'Ja',
            cancelButtonText: "Nein",
        })
        if (!answer.isConfirmed) return

        const locationsToUpdate = locations.filter(loc => loc.parents.includes(locationToUpdate._id))
        locationsToUpdate.push(locationToUpdate)

        try {
            for (const myLocationToUpdate of locationsToUpdate) {
                let needsUpdate = false
                if(type === 'email') {
                    if (shouldGetUvi && !myLocationToUpdate?.attributes?.includes('uvi_email')) {
                        needsUpdate = true
                        myLocationToUpdate.attributes.push('uvi_email')
                    } else if (!shouldGetUvi && myLocationToUpdate?.attributes?.includes('uvi_email')) {
                        myLocationToUpdate.attributes = myLocationToUpdate.attributes.filter(attr => attr !== 'uvi_email')
                        needsUpdate = true
                    }
                }
               
                if(type === 'download') {
                    if(shouldDownloadUvi && !myLocationToUpdate?.attributes?.includes('uvi_download')) {
                        needsUpdate = true
                        myLocationToUpdate.attributes.push('uvi_download')
                    } else if (!shouldDownloadUvi && myLocationToUpdate?.attributes?.includes('uvi_download')) {
                        myLocationToUpdate.attributes = myLocationToUpdate.attributes.filter(attr => attr !== 'uvi_download')
                        needsUpdate = true
                    }
                }

                if (needsUpdate) {
                    await Axios_client.backendClient().put(`${process.env.REACT_APP_API_OPENMETER}/location`, myLocationToUpdate)
                }
            }
        } catch (err) {
            console.log('error at updating location', err);
            errorToast('uVi-Status konnte nicht bearbeitet werden')
        }
        await getAllLocations({ filter: true })
        successToast('uVi-Status wurde erfolgreich bearbeitet')
    }

    const moveLocation = async (location_id) => {
        console.log('move location_id', location_id);

        let html = `
            <p>Auf welchen Kunden wollen Sie die Liegenschaft und alle dazugehörigen Mieteinheiten/Sensoren/Gateways/Nutzer umziehen?</p>
            <select class="form-select" aria-label="Kunde auswählen" id="selectOrg">
                <option value="">Bitte auswählen</option>
        `;
        organizations.map( org => {
            html += `<option key=${org._id} value=${org._id} >${org.name}</option>`
        })
        html += `</select>`

        Swal.fire({
            icon: "question",
            title: `Liegenschaft umziehen `,
            allowOutsideClick: true,
            allowEscapeKey: false,
            allowEnterKey: false,
            showConfirmButton: true,
            showCancelButton: true,
            confirmButtonText: 'Umziehen',
            background: localStorage.getItem('theme-preference') === 'dark-layout' ? '#161D31' : '#fff',
            cancelButtonText: 'Abbrechen',
            html,
            preConfirm: () => {
                const newOrg = Swal.getPopup().querySelector('#selectOrg').value
                if (newOrg === '') {
                    Swal.showValidationMessage(`Bitte neuen Kunden auswählen.`)
                }
                return {newOrg}
            }
        })
            .then(async (results) => {
                if (!results.isConfirmed || results.isDenied || results.isDismissed) return;
                console.log(results);
                Axios_client.backendClient().get(`${process.env.REACT_APP_API_OPENMETER}/location/transfer?new_organization_id=${results.value.newOrg}&location_id=${location_id}`, {})
                    .then(async (response) => {
                        await getGroups()
                        await getAllLocations({organization: organization._id})
                        setGroup({})
                        const html = `
                            <span>Umgezogen wurden:</span> <br>
                            <span>Nutzereinheiten: ${response.data.locations}</span> <br>
                            <span>Zähler: ${response.data.sensors}</span> <br>
                            <span>Gateways: ${response.data.gateways}</span> <br>
                            <span>Mieter: ${response.data.tenants}</span> <br>
                            <span>Gruppen: ${response.data.groups}</span>
                        `
                        Swal.fire({
                            icon: "success",
                            title: `Umzug erfolgreich!`,
                            allowOutsideClick: true,
                            confirmButtonText: 'Schließen   ',
                            background: localStorage.getItem('theme-preference') === 'dark-layout' ? '#161D31' : '#fff',
                            html
                        })
                    })
                    .catch(err => {
                        console.log(err)
                    })
            })
    }

    const deleteCompleteLocation = async (location_id) => {
        Swal.fire({
            title: "Sicher?",
            text: "Damit wird die Liegenschaft mit allen untergeordneten Mieteinheiten und Sensoren gelöscht. Gateways werden zu Molliné verschoben",
            showCancelButton: true,
            confirmButtonText: "Löschen",
            showLoaderOnConfirm: true,
            preConfirm: async () => {
                try {
                    const response = await Axios_client.backendClient().delete(`${process.env.REACT_APP_API_OPENMETER}/location/complete?location_id=${location_id}`, { timeout: 50000 })
                    getAllLocations({ organization_id: organization._id })
                    return response
                } catch (err) {
                    if(!!err?.response?.data) errorToast(err?.response?.data)
                    else errorToast(err)
                }
            },
            allowOutsideClick: () => !Swal.isLoading()
        }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire({
                    title: 'Löschen erfolgreich!',
                    text: `Es wurden erfolgreich ${result.value.data.deleted_locations} Liegenschaften/Mieteinheiten und ${result.value.data.deleted_sensors} Sensoren gelöscht sowie ${result.value.data.moved_gateways} Gateways verschoben`,
                    confirmButtonText: 'Ok',
                })
            }
        });

    }

    const actions = {
        locationTypes,
        setLocationTypes,

        getAllLocations,
        locationUpdate,

        addChildLocation,
        removeChildLocation,

        addParentLocation,
        removeParentLocation,

        getMonthlyConsumptionReport,
        setLocations,
        setSearchLocations,
        setLocation,
        getLocation,
        searchLocationsByAddress,
        capitalize,
        getLocationAddress,

        locationIcon,
        locations,
        searchLocations,
        location,
        addComment,
        deleteComment,
        deleteLocation,
        locationsChanged,
        locationChanged,
        searchLocationsChanged,
        errorToast,
        successToast,
        doYouReallyWantToSave,
        addLocationUser,
        removeLocationUser,
        toggleUviAttribute,
        moveLocation,
        deleteCompleteLocation
    }

    return (
        <Fragment>
            <LocationsContext.Provider value={actions}>
                {children}
            </LocationsContext.Provider>
        </Fragment>
    )

}

export default LocationsProvider;