import React, {Fragment, useEffect, useContext } from 'react';
import {SensorsContext} from "../../../../context/Devices/Sensors";
import { CSVLink, CSVDownload } from "react-csv";

const MbusSensorCoverage = () => {

    const { sensors, sensorsChanged, sanitizedSensor, roundDecimal } = useContext(SensorsContext);

    useEffect(() => {

    }, [sensors, sensorsChanged]);


    const UI = () => {
        let count = 0;
        let date = new Date()
        let mbus = sensors.filter(sensor => sensor.attributes.includes('mbus'));
        let sensorArray = [];

        for(let sensor of mbus){
            if(sensor.state === undefined || sensor.state.date === undefined){
                sensorArray.push(sanitizedSensor(sensor));    //Add bad sensor to report
                continue;
            }
            let sDate = new Date(sensor.state.date)
            let hours = Math.abs(date - sDate) / 36e5;
            if(hours < 360){
                count ++
            }else{
                sensorArray.push(sanitizedSensor(sensor)); //Add bad sensor to report
            }

        }

        let percent = ( (count / mbus.length) * 100).toFixed(1)
        percent = roundDecimal(percent)
        if(isNaN(percent)) percent = 0;


        if(mbus.length >0) {
            if(percent < 100){
                return (
                    <Fragment>
                        <div className="col-xl-2 col-md-4" id="widgetMbusSensorCoverage">
                            <CSVLink
                                data={sensorArray}
                                filename={"m-bus-poor-sensor-report.csv"}
                            >
                                <div className="card text-center " title="Prozentsatz der Zähler, die innerhalb von 15 Tagen Daten gesendet haben">
                                    <span className="badge rounded-pill bg-info fa-fade badge-up" id="sensorCoverageDownloadIcon"><i class="fa-solid fa-download"></i></span>
                                    <div className="card-body align-self-center">
                                        <div className="mx-auto my-1" style={{display: "inline-flex", alignItems: "center"}}>
                                            <i className={`fa-solid fa-network-wired fa-4x text-${(+percent < 96)? 'warning':'success'}`}></i>
                                        </div>
                                        <p className="mb-0 text-muted">MBUS-Abdeckung</p>
                                        <h2 className="mb-0 line-ellipsis ">{percent}%</h2>
                                    </div>
                                </div>
                            </CSVLink>
                        </div>
                    </Fragment>
                )
            }

            return (
                <Fragment>
                    <div className="col-xl-2 col-md-4" id="mbusSensorNotFullCoverageCounter">
                        <div className="card text-center " title="Prozentsatz der Zähler, die innerhalb von 15 Tagen Daten gesendet haben">
                            <div className="card-body align-self-center">
                                <div className="mx-auto my-1" style={{display: "inline-flex", alignItems: "center"}}>
                                    <i className={`fa-solid fa-network-wired fa-4x text-${(+percent < 96)? 'warning':'success'}`}></i>
                                </div>
                                <p className="mb-0 text-muted">MBUS-Abdeckung</p>
                                <h2 className="mb-0 line-ellipsis ">{percent}%</h2>
                            </div>
                        </div>
                    </div>
                </Fragment>
            )
        }else {
            return (
                <Fragment></Fragment>
            )
        }


    }

    return(
        <Fragment>
            {UI()}
        </Fragment>
    )
}

export default MbusSensorCoverage;