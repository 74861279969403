import React, {Fragment, useState, useEffect, useContext, useRef } from 'react';
import {LocationsContext} from "../../../context/Locations/Locations";
import {SensorsContext} from "../../../context/Devices/Sensors";
import uniqid from 'uniqid';
import {useRouter} from "../../Shared/Router/Router";
import capitalizeUtil from "../../../utils/capitalizeUtils"

const Chart = React.lazy(() => import('react-apexcharts'), { ssr: false });



const ConsumptionChart = (props) => {

    const [data, setData] = useState([]);
    const [err, setError] = useState(false);
    const { locationIcon } = useContext(LocationsContext);
    const { medium_icon, sensors } = useContext(SensorsContext);

    const [year, setYear] = useState( props.year ? props.year : new Date().getFullYear() )
    const [height, setHeight] = useState( props.height ? props.height : 250)
    const [showLocationAddress, setLocationAddress] = useState( props.showLocationAddress ? props.showLocationAddress : false)
    const [showPlaceholder, setPlaceholder] = useState( props.showPlaceholder ? props.showPlaceholder : false)
    const propsLocationChanged = useCompare(props.location)

    const router = useRouter()

    useEffect( () => {

        if(propsLocationChanged === true ) try{chartBuilder()}catch(err){}

    },[ props, year, height, data, showLocationAddress, showPlaceholder, propsLocationChanged ]);

    function useCompare (val) {
        const prevVal = usePrevious(val)
        return prevVal !== val
    }
  
    function usePrevious(value) {
        const ref = useRef();
        useEffect(() => { ref.current = value });
        return ref.current;
    }

    const chartBuilder = () => {
        return new Promise( (resolve, reject) => {
            const charts = []

            if(!props?.location?.consumption[year]) return
            let consumption = Object.entries(props.location.consumption[year])
            let compare = null;
            try{ compare = props.location.comparison[ new Date().getFullYear() ]}catch(err){}

            for(const [index, value] of consumption.entries()){

                let id = uniqid();
                let series = []
                let options = {
                    chart: {
                        height: height,
                        type: 'line',
                        sparkline: {
                            show: true
                        },
                        zoom: {
                            enabled: false
                        },
                        toolbar: {
                            show: false
                        }
                    },
                    dataLabels: {
                        enabled: false
                    },
                    stroke: {
                        curve: 'straight'
                    },
                    colors: ['#ff0000'],
                    title: {
                        text: '',
                        align: 'left'
                    },
                    xaxis: {
                        categories: ['Jan', 'Feb', 'Mar', 'Apr', 'Mai', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dez'],
                    }
                }
                let medium_code = value[0]
                let monthVals = value[1];
                let compareChart = {
                    name: "Durchschnitt",
                    type: "bar",
                    data: null,
                }

                switch(medium_code){
                    case'0x8':
                        options.title.text = "HEIZUNGSVERBRAUCH IN KWH";
                        options.colors = ['#dc3545'];
                        options.icon =  medium_icon({sensor:{type:'',medium_code: '0x8'}})//`<i id="icon-${id}" class="text-danger fa-solid fa-fire text-primary"></i>`;
                        monthVals = value[1]
                        break;
                    case'0x6':
                        options.title.text = "WARMWASSERVERBRAUCH IN KWH";
                        options.colors = ['#ffc107'];
                        options.icon = medium_icon({sensor:{type:'',medium_code: '0x6'}}) //`<i id="icon-${id}" class="text-warning fa-solid fa-droplet text-primary"></i>`;
                        monthVals = value[1]
                        break;
                    case'0x7':
                        options.title.text = "KALTWASSERVERBRAUCH IN M3";
                        options.colors = ['#007bff'];
                        options.icon = `<i id="icon-${id}" class="text-primary fa-solid fa-droplet text-primary"></i>`;
                        monthVals = value[1]
                        break;
                    case'0x16':
                        options.title.text = "KALTWASSERVERBRAUCH IN M3";
                        options.colors = ['#007bff'];
                        options.icon = `<i id="icon-${id}" class="text-primary fa-solid fa-droplet"></i>`;
                        monthVals = value[1]
                        break;
                    case'0x4':
                        const locSensors = sensors.filter(snsr => snsr.location_id === props.location._id)
                        const combinedSensors = locSensors.filter(snsr=> snsr.attributes.includes('hzg_wsr'))
                        if(combinedSensors.length > 0) {
                            options.title.text = "HEIZUNGS-/WARMWASSERVERBRAUCH KOMBINIERT IN KWH";
                        }else {
                            options.title.text = "HEIZUNGSVERBRAUCH IN KWH";
                        }
                        options.colors = ['#dc3545'];
                        options.icon = medium_icon({sensor:{type:'',medium_code: '0x4'}}) //`<i id="icon-${id}" class="text-danger fa-solid fa-fire text-primary"></i>`;
                        monthVals = value[1]
                        break;
                    case'0x3':
                        options.title.text = "GAS IN M3";
                        options.colors = ['#dc3545'];
                        options.icon = medium_icon({sensor:{type:'',medium_code: '0x3'}}) //`<i id="icon-${id}" class="text-danger fa-solid fa-fire text-primary"></i>`;
                        monthVals = value[1]
                        break;
                    case'0xa':
                        options.title.text = "KLIMA (KALT) IN KWH";
                        options.colors = ['#03c5fe'];
                        options.icon = `<i id="icon-${id}" class="text-primary fa fa-snowflake"></i>`;
                        monthVals = value[1]
                        break;
                    case'0xb':
                        options.title.text = "KLIMA (KALT) IN KWH";
                        options.colors = ['#03c5fe'];
                        options.icon = `<i id="icon-${id}" class="text-primary fa fa-snowflake"></i>`;
                        monthVals = value[1]
                        break;
                    case'0xd':
                        options.title.text = "COMBI (WÄRME/KALT) IN KWH";
                        options.colors = ['#800080'];
                        options.icon = `<i id="icon-${id}" class="text-danger fa fa-snowflake"></i>`;
                        monthVals = value[1]
                        break;
                        
                    default :
                        continue;
                        break;
                }
                if(!!compare && !!compare[medium_code]) compareChart.data = compare[medium_code];
                monthVals.map( (v,i) => { if(monthVals[i] !== 0 && v !== null) monthVals[i] = +v.toFixed(2) })
                let chartObj1 = {
                    name: "Energie",
                    type: "line",
                    data: monthVals
                }
                options.colors.push('#D3D3D3')
                series.push(chartObj1);
                if(!!compareChart.data) series.push(compareChart);

                charts.push({id, series, options})
            }
            setData(charts)
            return resolve()
        }) 
    }

    const locationAddress = () => {
        if(!props.location) return;
        let loc = props.location
        return `${capitalizeUtil.capitalize(loc?.address?.street)} ${loc?.address?.house_number}, ${(loc?.address?.street1 !== '') ? `ME ${loc?.address?.street1}, ` : ''}${loc?.address?.post_code} ${capitalizeUtil.capitalize(loc?.address?.city)}, ${loc?.address?.country.toLocaleUpperCase()}`
    }

    const UI = () => {
        /**
         * Position chart icon beside title
         */
        data.map( (obj, index) => {
            let iconDiv = document.createElement('div')
            iconDiv.innerHTML = obj.options.icon
            iconDiv.style.position = 'absolute';
            iconDiv.style.left = '1rem';
            iconDiv.style.top = '1.5rem';
            let t1 = document.getElementById(obj.id);
            if(t1) t1.append(iconDiv)
        })
        
        if(data?.length > 0 && !err){
            return (
                <Fragment>
                    {
                        data.map( (obj, index) => {
                            return (
                                <div className="col-md-6 col-xl-6 col-sm-12 flex-fill flex-shrink" key={uniqid()}>
                                    <div className="card ">
                                        <div className="card-body">
                                            <div id={`${obj.id}`}>
                                                <Chart 
                                                    options={obj.options} 
                                                    series={obj.series}
                                                    type={'line'}
                                                    width={'100%'} 
                                                    height={height} 
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                    {showLocationAddress === true && 
                        <div className="col-12">
                            <div className="card clickable" onClick={(e)=>router.push(`/details/location/${(props.location)? props.location._id : props.location?._id}` )}>
                                <div className="card-header border-bottom">
                                    <h4 className="card-title"><span dangerouslySetInnerHTML={{__html:locationIcon({location: props.location, size:1})}}></span> {locationAddress()}</h4>
                                </div>
                            </div>
                        </div>
                    }
                </Fragment>
            )
        }else if(showPlaceholder === true){
            return (
                <Fragment>
                    <div className="col-12 text-center">
                        <div className="card ">
                            <div className="card-body">
                                <h4 style={{marginBottom:"0"}}>Aktuell liegen keine Verbrauchsdaten für diese Nutzer-/Mieteinheit vor</h4>
                            </div>
                        </div>
                    </div>
                </Fragment>
            )
        }
    };

    return (
        <Fragment>
            {UI()}
        </Fragment>
    )
}

export default ConsumptionChart;