import React, {createContext, Fragment, useContext, useEffect, useRef, useState} from 'react';
import {useRouter} from "../../components/Shared/Router/Router";
import {AuthContext} from "../Auth/AuthProvider";
import {hasPermission} from "../../components/Dashboard/reactPermissionsWrapper";
import AxiosClient from "../../utils/axios_client";
import Swal from '@molline/sweetalert2';
import de from "../../components/Dashboard/tables/datatable.de.json";
import CryptoJS from "crypto-js";
import Cookie from 'js-cookie';
const $ = require('jquery');
$.DataTable = require('datatables.net');

export const OrganizationContext = createContext();

let storageBasedOrganization = localStorage.getItem('organization')
const OrganizationProvider = ({ children, context }) => {

    const { user, setUser } = useContext(AuthContext);
    const [organizations, setOrganizations] = useState([]);
    const [organization, setOrganization] = useState({});
    const [mollineOrganization, setMollineOrganization] = useState({});
    const organizationChanged = useCompare(organization);
    const organizationsChanged = useCompare(organizations);
    const userChanged = useCompare(user)

    useEffect(() => {
 
      //  if(!!storageBasedOrganization) setOrganization(JSON.parse(storageBasedOrganization))

        let orgStore = localStorage.getItem('organization')
        try { orgStore = JSON.parse(orgStore) } catch (err) { }
        if (orgStore !== null) {
            if (organization?._id === undefined || organization._id !== orgStore._id) {
                setOrganization(orgStore)
            }
        }

        if(userChanged === true){
          //  setOrganization(user.organization)
            getOrganizations();
        }

        if(userChanged === true && !user.organization && !!user.organization_id){
            getOrganizations().then((orgs => {
                let userOrg = orgs.filter( o => o._id === user.organization_id)[0]
                setOrganization(userOrg)
            }))
        }

       //if(organizationChanged === true) localStorage.setItem('organization', JSON.stringify(organization))
      
    }, [organization, organizations, organizationChanged, organizationsChanged, user, userChanged])

    function useCompare(val) {
        const prevVal = usePrevious(val)
        return prevVal !== val
    }

    function usePrevious(value) {
        const ref = useRef();
        useEffect(() => {
            ref.current = value;
        });
        return ref.current;
    }

    const getOrganizations = () => {
        return new Promise(async (resolve, reject) => {
            if(!hasPermission({user, scopes:['organization.view.any', 'organization.view.own']})) return;
            AxiosClient.backendClient().get(`${process.env.REACT_APP_API_OPENMETER}/organization/all`)
            .then(response => {
                //Sort by name
                response.data.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))
                setOrganizations(response.data)
                return resolve(response.data)
            })
            .catch(err => {
                return reject(err)
            })
        })
    }

    const createOrganization = async (params) => {
        try {
            if( !hasPermission({user, scopes:['organization.create.any']}) ) return;
            const response = await AxiosClient.backendClient().post(`${process.env.REACT_APP_API_OPENMETER}/organization` , params)
            setOrganizations([...organizations, response.data])
        } catch (e) {
            if (e?.response?.status === 400) {
                throw new Error('Bitte füllen Sie alle mit * gekennzeichneten Felder aus!')
            }
            console.log('error at creating org', e);
            throw new Error(e)
        }
    }

    const deleteOrganization = async (organization_id) => {
        try {
            if( !hasPermission({user, scopes:['organization.delete.any']}) ) return;
            AxiosClient.backendClient().delete(`${process.env.REACT_APP_API_OPENMETER}/organization?organization_id=${organization_id}`)
            const updatedOrgs = organizations.filter(function (org) {
                return org._id !== organization_id
            });
            setOrganizations(updatedOrgs)
        } catch (e) {
            console.log('error at deleting org', e);
            throw new Error(e)
        }
    }

    const updateOrganization = async (params) => {
        try {
            if( !hasPermission({user, scopes:['organization.update.any']}) ) return;
            const response = await AxiosClient.backendClient().put(`${process.env.REACT_APP_API_OPENMETER}/organization` , params)
          //  setOrganization(response.data)
            getOrganizations().then().catch();
        } catch (e) {
            console.log('error at updating org', e);
            throw new Error(e)
        }
    }

    const switchOrganization = () => {
            let filteredOrgs = organizations
            if( !hasPermission({user, scopes:['organization.switch.any', 'organization.switch.own']}) ) return;
            // user is not allowed to switch to all organizations -> filter
            if(!user?.scopes?.includes('organization.switch.any')) {
                filteredOrgs = organizations.filter(org => user.organizations.includes(org._id))
            }

            Swal.fire({
                width: "80%",
                title: `Kunde wählen`,
                allowOutsideClick: true,
                allowEscapeKey: true,
                allowEnterKey: false,
                showConfirmButton: true,
                showCancelButton: true,
                background: localStorage.getItem('theme-preference') === 'dark-layout' ? '#161D31' : '#fff',
                confirmButtonText: 'Wechseln',
                cancelButtonText: 'Abbrechen',
                html: `<input class="hidden" id="orgID" type="text">
                    <table id="tblPickerOrganizations" class="table mb-0 row-border hover order-column w-100" role="grid">
                        <thead>
                            <tr>
                                <th >ID</th>
                                <th >Kunde</th>
                                <th >Straße</th>
                                <th >Stadt</th>
                            </tr>
                        </thead>
                    </table>`,
                willOpen: () => {
                    if (organizations.length > 0) {
                        let table;
                        if ($.fn.dataTable.isDataTable('#tblPickerOrganizations')) {
                            table = $('#tblPickerOrganizations').DataTable();
                        } else {
                            table = $('#tblPickerOrganizations').DataTable({
                                "language": de,
                                paging: true,
                                "pagingType": "full",
                                processing: true,
                                searching: true,
                                "data": filteredOrgs,
                                orderCellsTop: true,
                                fixedHeader: true,
                                "rowId": "_id",
                                "pageLength": 10,
                                order: [[1, "asc"]],
                                "columns": [
                                    { "data": "_id", visible: false },
                                    { "data": "name" },
                                    { "data": "address.street" },
                                    { "data": "address.city" }
                                ],
                                createdRow: function (row, data, dataIndex) {
    
                                }
                            });
                            $('#tblPickerOrganizations tbody').on('click', 'tr', function () {
                                document.getElementById('orgID').value = table.row(this).id();
                                $(this).toggleClass('selected');
                            });
    
                            $('.dataTables_length select').addClass('form-select');
                            $('.dataTables_filter input').addClass('form-control');
    
                            $(document).ready( function () {
                                function focusSearchInput(){
                                    $('div.dataTables_filter input').focus();
                                }
                                setTimeout(focusSearchInput, 1)
                            });
                        }
                    }
                },
                preConfirm: () => {
                    const org_id = Swal.getPopup().querySelector('#orgID').value
                    if (!org_id) {
                        Swal.showValidationMessage(`Bitte wählen Sie eine Organisation.`)
                    }
                    return { org_id }
                }
            })
            .then(async results => {
                if (results?.isConfirmed) {
                    const org_id = results.value.org_id
                    if( !hasPermission({user, scopes:['organization.switch.any', 'organization.switch.own']}) ) return;
                    AxiosClient.backendClient().get(`${process.env.REACT_APP_API_OPENMETER}/organization/switch?org_id=${org_id}`)
                    .then(result => {
                        setUser(result.data)
                        const key = Cookie.get('APP_KEY')
                        let encUser = CryptoJS.AES.encrypt( JSON.stringify(result.data), key ).toString();
                        localStorage.setItem('encuser', encUser);
                        localStorage.setItem('organization', JSON.stringify(result.data.organization));
                        setOrganization(result.data.organization)
                    })
                    .catch(err => {
                        console.log(err)
                        alert(err)
                    })
                }
            })
        
    }

    const actions = {
        organizations,
        organization,
        setOrganizations,
        setOrganization,
        getOrganizations,
        createOrganization,
        deleteOrganization,
        updateOrganization,
        organizationChanged,
        organizationsChanged,
        switchOrganization,
    }

    return (
        <Fragment>
            <OrganizationContext.Provider value={actions}>
                {children}
            </OrganizationContext.Provider>
        </Fragment>
    )

}

export default OrganizationProvider;