import React, {createContext, Fragment, useContext, useEffect, useRef, useState} from 'react';
import Axios_client from "../../utils/axios_client";
import {useRouter} from "../../components/Shared/Router/Router"
import {AuthContext} from '../Auth/AuthProvider';
import {hasPermission} from "../../components/Dashboard/reactPermissionsWrapper";
import {OrganizationContext} from '../Organization/Organization';
import {LocationsContext} from '../Locations/Locations';
import {GroupsContext} from '../Groups/Groups';
import Swal from '@molline/sweetalert2'
import { Buffer } from "buffer";

export const TenantContext = createContext();

const TenantsProvider = ({children, context}) => {

    const router = useRouter()
    const { user } = useContext(AuthContext);
    const { organization, organizations } = useContext(OrganizationContext);
    const { locations, location } = useContext(LocationsContext);
    const { group, setGroup, getGroups, groups, setGroups } = useContext(GroupsContext);

    const [tenant, setTenant] = useState({});
    const [tenants, setTenants] = useState([]);
    const [currTenant, setCurrTenant] = useState([]);
    const [isTenantsDone, setIsTenantsDone] = useState(false);

    const userChanged = useCompare(user)
    const organizationChanged = useCompare(organization)
    const tenantsChanged = useCompare(tenants)
    const tenantChanged = useCompare(tenant)
    const locationsChanged = useCompare(locations)
    const locationChanged = useCompare(location)
    const groupChanged = useCompare(group)

    useEffect(() => {

        if(userChanged === true || organizationChanged === true || locationsChanged === true ){
            getAllTenants();

        }

        if(locationChanged === true){
            getCurrentTenant(location._id)
        }

    },[user, userChanged, groupChanged, group, groups, tenants, tenant, organization, organizationChanged, locations, locationsChanged, location, locationChanged]);

    function useCompare (val) {
        const prevVal = usePrevious(val)
        return prevVal !== val
    }

    function usePrevious(value) {
        const ref = useRef();
        useEffect(() => {
            ref.current = value;
        });
        return ref.current;
    }

    const capitalize = (str) => {
        if (!str) return str;
        return `${str.charAt(0).toUpperCase()}${str.slice(1)}`
    }

    const successToast = (msg) => {
        Swal.fire({
            toast: true,
            position: 'bottom-end',
            timer: 3000,
            timerProgressBar: true,
            allowOutsideClick: true,
            text: msg,
            showConfirmButton: false,
            customClass: {
                popup: "bg-success"
            }
        })
    }

    const errorToast = (err) => {
        Swal.fire({
            toast: true,
            position: 'bottom-end',
            timer: 3000,
            timerProgressBar: true,
            allowOutsideClick: true,
            text: err,
            showConfirmButton: false,
            customClass: {
                popup: "bg-danger"
            }
        })
    }

    const doYouReallyWantToSave = () => {
        return new Promise(async (resolve) => {
            const answer = await Swal.fire({
                title: 'Sicher?',
                text: 'Damit werden die Daten gespeichert',
                showCancelButton: true,
                confirmButtonText: 'Ja',
                cancelButtonText: "Nein",
            })
            if (!answer.isConfirmed) return resolve(false);
            return resolve(true);
        })
    }

    const formatDate = (value) => {
        if (value === undefined) {
            return;
        }
        let date = new Date(value)
        var options = {year: 'numeric', month: 'long', day: 'numeric',};
        return date.toLocaleDateString("de-DE", options);
    }

    /**
     * Returns an array of tenants CURRENTLY living in the location. This is determined by IN/OUT dates 
     */
    const getTenant = (tid) => {
        if( user && hasPermission({user, scopes:['tenant.view.any', 'tenant.view.own']}) ){
            Axios_client.backendClient().get(`${process.env.REACT_APP_API_OPENMETER}/tenant?tenant_id=${tid}`, {})
            .then( response => {
                console.log('122 resp', response.data)
                response.data.organization = {}
                if(response.data.organization_id){
                    let org = organizations.filter(org => org._id === response.data.organization_id)[0]
                    if(!!org && !!org.name) response.data.organization = org
                }
                setTenant(response.data)
                return response.data;
            })
            .catch( err => {
                console.log('TENANT FETCHING ERROR 83', err.message)
                return err
            })
        }
    }

    const getAllTenants = async () => {
        setIsTenantsDone(false);
        if(!organization._id){
            setTenants([]);
            setIsTenantsDone(true);
            return;
        }
        if( user && hasPermission({user, scopes:['tenant.view.any', 'tenant.view.own']}) ){
            Axios_client.backendClient().get(`${process.env.REACT_APP_API_OPENMETER}/tenant/all?organization_id=${organization._id}`)
            .then( async response => {
                let filterArray = []
                let filterLocations = [];

                if(!!group && group?.name) filterLocations = locations.filter(loc => group.members.includes(loc._id))

                for(const [index, ten] of response.data.entries()){
                    let tenantLocation = group?.name !== undefined && group?.name.length > 1 ? filterLocations.filter(loc => loc._id.toString() === ten.location_id)  :  locations.filter(loc => loc._id.toString() === ten.location_id)
                    if(!!tenantLocation[0]) response.data[index].location = tenantLocation[0]
                    else response.data[index].location = {}
                    const match = group?.name !== undefined && group?.name.length > 1  ? filterLocations.filter(loc => loc._id === ten.location_id) : locations.filter(loc => loc._id === ten.location_id)
                    if(match.length !== 0) filterArray.push(ten)
                }
                setTenants(filterArray)
                setTimeout(()  => {
                    setIsTenantsDone(true);
                }, 2000);

                return filterArray;
            })
            .catch( err => {
                console.log('TENANTS FETCHING ERROR 83', err)
                setIsTenantsDone(true)
                return 'TENANTS FETCHING ERROR 83'
            })
        }
    }

    const getCurrentTenant = async (location_id) => {
        if (user && hasPermission({user, scopes: ['tenant.view.any', 'tenant.view.own']})) {
            return new Promise((resolve, reject) => {
                if (user && hasPermission({user, scopes: ['tenant.view.any', 'tenant.view.own']})) {
                    Axios_client.backendClient().get(`${process.env.REACT_APP_API_OPENMETER}/tenant/current?location_id=${location_id}`, {})
                    .then(response => {
                        if(response.data !== currTenant) setCurrTenant(response.data)
                        return resolve(response.data);
                    })
                    .catch(err => {
                        console.log('CURRENT TENANT BY LOCATION_ID FETCHING ERROR 83', err.message)
                        return reject(err)
                    })
                }
            })

        }
    }

    const createTenant = () => {

        let html = '<div class="row " id="tenant_movein_form">' +
        '<div class="col-12 col-sm-6" >' +
            '<label class="form-label">Vorname *</label>'+
            '<input id="frm_first_name" type="text" class="form-control form-control-lg mb-1" placeholder="Vorname *" required />' +
        '</div>'+
        '<div class="col-12 col-sm-6">' +
            '<label class="form-label ">Nachname *</label>'+
            '<input id="frm_last_name" type="text" class="form-control form-control-lg mb-1" placeholder="Nachname *" required />'+
        '</div>'+
        '<div class="col-12 col-sm-6">' +
                '<label class="form-label">Email</label>'+
                '<input id="frm_email" type="email" class="form-control form-control-lg mb-1" placeholder="Email"   />'+
        '</div>'+
        '<div class="col-12 col-sm-6">' +
            '<label class="form-label">Mobile </label>'+
            '<input id="frm_mobile" type="number" class="form-control form-control-lg mb-1" placeholder="Mobile"  />'+
        '</div>'+
        '<div class="col-12 col-sm-6">' +
            '<label class="form-label">Beschreibung</label>'+
            '<input id="frm_description" type="text" class="form-control form-control-lg mb-1" placeholder="Beschreibung"  />'+
        '</div>' +
         '<div class="col-12 col-sm-6">' +
                '<label class="form-label">Einzug Datum *</label>'+
                '<input id="frm_in_date" type="date" class="form-control form-control-lg mb-1" placeholder="Einzug *" required />'+
         '</div>'+
        '<div class="col-12 col-sm-6">' +
                '<label class="form-label">Kunde *</label>'+
                `<input id="frm_org" type="text"  value="${organization.name}" class="form-control form-control-lg mb-1" placeholder="Organization" disabled="true"  />`+
          '</div>' ;
        let orderedLocs = [...locations]
        orderedLocs = orderedLocs.filter(loc => loc.type !== "multi_premise")
        orderedLocs.sort(function (a, b) {
            const bLocString = `${b.address.street}${a.address.street.house_number}}${b.address.street1 === "" ? "" : b.address.street1}`
            const aLocString = `${a.address.street}${b.address.street.house_number}}${a.address.street1 === "" ? "" : a.address.street1}`
            return aLocString.localeCompare(bLocString, undefined, {
                numeric: true
            })
        });
           html +=   '<div class="col-12 col-sm-6 ">' +
                         '<label class="form-label">Mieteinheit (Straße, Hausnr., Wohnung, interne Nummer) * </label>'+
                '<div class="input-group"><span class="input-group-text"><i class="fa-solid fa-home"></i></span><select name="locations" id="frm_locations" class="form-select  form-select-lg" >'
                            orderedLocs.map(loc => {
                                html += `<option value="${loc._id}">${capitalize(loc.address.street)} ${loc.address.house_number}, ${loc.address.street1 !== '' ? `ME ${capitalize(loc.address.street1)}` : ''} ${(loc.token !== '' && loc.token !== undefined) ? ' (' + loc.token + ')' : ''}</option>`;
                            })
                html += '</select></div></div>'+

        '</div>';


        Swal.fire({
            title: 'Neuen Nutzer einziehen',
            iconHtml: '<i class="fa-solid fa-user"/>',
            customClass: {
                icon:"border-0"
            },
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            showConfirmButton: true,
            confirmButtonText: "Nutzer einziehen",
            cancelButtonText: "Abbrechen",
            showCancelButton: true,
            background: localStorage.getItem('theme-preference') === 'dark-layout' ? '#161D31' : '#fff',
            width: '70rem',
            html: html,

            preConfirm: () => {
                const first_name = Swal.getPopup().querySelector('#frm_first_name').value
                const last_name = Swal.getPopup().querySelector('#frm_last_name').value
                const email = Swal.getPopup().querySelector('#frm_email').value
                const mobile = Swal.getPopup().querySelector('#frm_mobile').value
                const description = Swal.getPopup().querySelector('#frm_description').value
                const in_date = Swal.getPopup().querySelector('#frm_in_date').value
                let location_id = null;
                let phones = [];

                if (Swal.getPopup().querySelector('#frm_locations') !== null) {
                    location_id = Swal.getPopup().querySelector('#frm_locations').value;
                }

                let organization_id = organization._id;
                if (!first_name || !last_name || !in_date || !location_id || !organization_id) {
                    Swal.showValidationMessage(`Alle Felder mit * sind Pflichtfelder`)
                }

                if(mobile) {
                    let numObj = {"type": "work", "primary": true, "number": ""};
                    numObj.number = mobile;
                    phones.push(numObj)
                }
                return {
                    first_name,
                    last_name,
                    in_date,
                    location_id,
                    organization_id,
                    email,
                    phones,
                    description,
                }
            }
        })
        .then(results => {
            if (!results.isConfirmed) return;
            if(user && hasPermission({user, scopes:['tenant.create.any', "tenant.create.own"]})) {
                Axios_client.backendClient().post(`${process.env.REACT_APP_API_OPENMETER}/tenant/create`, results.value)
                .then( response => {
                    successToast('Nutzer erfolgreich eingezogen')
                    getAllTenants()
                    return response.data;
                })
                .catch( err => {
                    errorToast(err.message)
                    return err
                })
            }else {
                errorToast('Sie haben nicht die Befugnis, neue Nutzer einzuziehen.')
                return;
            }
        })
    }


    const moveOutTenant = (tenantID) => {
        if( user && hasPermission({user, scopes:['tenant.update.any', 'tenant.update.own']}) ){
            Axios_client.backendClient().get(`${process.env.REACT_APP_API_OPENMETER}/tenant?tenant_id=${tenantID}`, {})
                .then( response => {

                    console.log('respon.data', response.data)

                    if(!!response && !!response.data) {
                        let html = '<div class="row " id="tenant_movein_form">' +
                            '<div class="col-12 col-sm-6" >' +
                            '<label class="form-label">Vorname </label>'+
                            `<input id="frm_first_name" type="text" class="form-control form-control-lg mb-1" value="${capitalize(response.data?.first_name)}" placeholder="Vorname *" disabled="true"  />` +
                            '</div>'+
                            '<div class="col-12 col-sm-6">' +
                            '<label class="form-label ">Nachname </label>'+
                            `<input id="frm_last_name" type="text" class="form-control form-control-lg mb-1" value="${capitalize(response.data?.last_name)}" placeholder="Nachname *" disabled="true"  />`+
                            '</div>'+
                            '<div class="col-12 col-sm-6">' +
                            '<label class="form-label">Email</label>'+
                            `<input id="frm_email" type="email" class="form-control form-control-lg mb-1" value="${capitalize(response.data?.email)}" placeholder="Email" disabled="true"   />`+
                            '</div>'+
                            '<div class="col-12 col-sm-6">' +
                            '<label class="form-label">Einzug Datum </label>'+
                            `<input id="frm_in_date" type="text" class="form-control form-control-lg mb-1" value="${formatDate(response.data.in_date)}" placeholder="Einzug *" disabled="true" required  />`+
                            '</div>'+
                            '<div class="col-12 col-sm-6">' +
                            '<label class="form-label">Kunde </label>'+
                            `<input id="frm_org" type="text"  value="${organization.name}" class="form-control form-control-lg mb-1" placeholder="Organization" disabled="true" disabled="true"  />`+
                            '</div>' ;
                        let orderedLocs = [...locations]
                        orderedLocs = orderedLocs.filter(loc => loc.type !== "multi_premise")
                        orderedLocs.sort(function (a, b) {
                            if (a.address.street < b.address.street) {
                                return -1;
                            }
                            if (a.address.street > b.address.street) {
                                return 1;
                            }
                            return 0;
                        });

                        html +=   '<div class="col-12 col-sm-6 ">' +
                            '<label class="form-label">Mieteinheit (Straße, Hausnr., Wohnung, Plz, Ort)  </label>'+
                            '<div class="input-group"><span class="input-group-text"><i class="fa-solid fa-home"></i></span><select name="locations" id="frm_locations" class="form-select  form-select-lg" disabled="true"  >'
                        orderedLocs.map(loc => {
                            const isSelected = response.data.location_id === loc._id ? 'selected' : ''; // Determine if the option should be selected
                            html += `<option value="${loc._id}" ${isSelected}>${capitalize(loc.address.street)} ${loc.address.house_number}, ${loc.address.street1 !== '' ? `ME ${capitalize(loc.address.street1)},` : ''} ${loc.address.post_code} ${capitalize(loc.address.city)}</option>`;
                        })
                        html += '</select></div></div>'+

                            '</div>';

                        html +=  `
                            <div class="row">
                                <div class="col-6">
                                    <label class="form-label">Auszugdatum * </label>
                                    <input id="frm_out_date" type="date" class="form-control form-control-lg mb-1" placeholder="Einzug *" required />
                                </div>
                            </div>`;


                        Swal.fire({
                            title: 'Nutzer ausziehen',
                            iconHtml: '<i class="fa-solid fa-user-slash"/>',
                            customClass: {
                                icon:"border-0"
                            },
                            allowOutsideClick: false,
                            allowEscapeKey: false,
                            allowEnterKey: false,
                            showConfirmButton: true,
                            confirmButtonText: "Nutzer ausziehen",
                            cancelButtonText: "Abbrechen",
                            showCancelButton: true,
                            background: localStorage.getItem('theme-preference') === 'dark-layout' ? '#161D31' : '#fff',
                            width: '70rem',
                            html: html,

                            preConfirm: () => {
                                const out_date = Swal.getPopup().querySelector('#frm_out_date').value

                                let organization_id = organization._id;
                                if (!out_date) {
                                    Swal.showValidationMessage(`Bitte wählen Sie zuerst ein Auszugsdatum`)
                                }
                                if(new Date( response.data.in_date).getTime() > new Date(out_date).getTime()) {
                                    Swal.showValidationMessage(`Das Einzugsdatum darf nicht nach dem Auszugsdatum liegen.`)
                                }
                                return {
                                    out_date
                                }
                            }
                        })
                            .then(results => {
                                if (!results.isConfirmed) return;
                                if(user && hasPermission({user, scopes:['tenant.update.any', 'tenant.update.own']})) {
                                    response.data.out_date = Swal.getPopup().querySelector('#frm_out_date').value;
                                    let currTenantParams = response.data;
                                    updateTenant(currTenantParams).then().catch().finally(() => {
                                        successToast( capitalize(currTenantParams.first_name)  + ' ' +  capitalize(currTenantParams.last_name) + 'wurde erfolgreich ausgezogen')
                                    });

                                }else {
                                    errorToast('Sie haben nicht die Befugnis, neue Nutzer einzuziehen.')
                                    return;
                                }
                            })
                    } else {
                        errorToast("Etwas ist schiefgelaufen, bitte versuchen Sie es erneut")
                    }
                })
                .catch( err => {
                    console.log('TENANT FETCHING ERROR 83', err.message)
                    errorToast("Beim Abrufen des Nutzer ist ein Fehler aufgetreten. Bitte versuchen Sie es später erneut.");
                    return err
                })
        }
    }

    const updateTenant = (updatedTenant) => {
        console.log('updatedTenant', updatedTenant);
        return new Promise( (resolve, reject) => {
            if(user && hasPermission({user, scopes:['tenant.update.any', 'tenant.update.own']})) {
            
                if(!!updatedTenant.out_date){
                    if( new Date(updatedTenant.in_date).getTime() > new Date(updatedTenant.out_date).getTime() ){
                        errorToast('Das Einzugsdatum darf nicht nach dem Auszugsdatum liegen.')
                        return reject()
                    }
                }

                Axios_client.backendClient().put(`${process.env.REACT_APP_API_OPENMETER}/tenant`, updatedTenant)
                .then( response => {

                    console.log(response.data)

                    setTenant(response.data)
                    getAllTenants()
                    successToast('Der Nutzer wurde erfolgreich aktualisiert')
                    return resolve(response.data);
                })
                .catch( err => {
                    console.log('TENANT UPDATE ERROR', err)
                    errorToast(err.message)
                    return reject(err)
                })
            }else {
                return resolve([]);
            }

        })
    }

    const deleteTenant = async (tenant_id) => {
        if (!tenant_id) return;

        const answer = await Swal.fire({
            icon: 'error',
            title: 'Sicher?',
            text: 'Damit werden die Daten gelöscht',
            showCancelButton: true,
            confirmButtonText: 'Ja',
            cancelButtonText: "Nein",
        })
        if (!answer.isConfirmed) return;
        
        return new Promise( async (resolve, reject) => {
            if(user && hasPermission({user, scopes:['tenant.delete.any', 'tenant.delete.own']}))  {
                Axios_client.backendClient().delete(`${process.env.REACT_APP_API_OPENMETER}/tenant?_id=${tenant_id}`)
                .then( response => {
                    getAllTenants();
                    successToast('Nutzer wurde erfolgreich gelöscht.')
                    return;
                 })
                .catch( err => {
                    errorToast(err.message)
                    return;
                })
            } else {
                return resolve([]);
            }
        })
    }

    const exportTenantReports = () => {
        if( user && hasPermission({user, scopes:['tenant.view.any', 'tenant.view.own']}) ){
            Axios_client.backendClient().get(`${process.env.REACT_APP_API_OPENMETER}/tenant/export?organization_id=${organization._id}`)
            .then( async response => {
                let orgName = organization.name.replaceAll(' ','_');
                orgName.trim()

                let fileName = `${new Date().getTime()}_Mieter_${orgName}.csv`
                let buffer = Buffer.from(response.data, "utf-8")
                let blob = new Blob([buffer], { type: 'text/csv' })
                let fileURL = URL.createObjectURL(blob);
                let link = document.createElement('a');
                link.href = fileURL;
                link.setAttribute('download', fileName);
                link.setAttribute('target', '_blank');
                document.body.appendChild(link);
                link.dispatchEvent(
                    new MouseEvent('click', {
                        bubbles: true,
                        cancelable: true,
                        view: window
                    })
                );
                link.remove();
                
            })
            .catch( err => {
                errorToast(err.message)
                return
            })
        }else{
            errorToast('Sie haben nicht die Befugnis, diesen Bericht anzuzeigen.')
        }
    }

    const actions = {
        getAllTenants,
        getTenant,
        getCurrentTenant,
        createTenant,
        moveOutTenant,
        deleteTenant,
        updateTenant,
        setTenant,
        tenants,
        tenant,
        currTenant,
        successToast,
        errorToast,
        tenantsChanged,
        tenantChanged,
        doYouReallyWantToSave,
        formatDate,
        exportTenantReports,
        isTenantsDone,

    }
    return (
        <Fragment>
            <TenantContext.Provider value={actions}>
                {children}
            </TenantContext.Provider>
        </Fragment>
    )
}
export default TenantsProvider;
