import React, {Fragment, useState, useEffect, useContext, useRef } from 'react';
import {GatewaysContext} from "../../../../context/Devices/Gateways";
import OrgCookieCrumb from "../../../../components/Dashboard/orgCookieCrumb";
import {SensorsContext} from "../../../../context/Devices/Sensors";
import {LocationsContext} from "../../../../context/Locations/Locations";

import {SocketContext} from "../../../../context/Socket/Sockets";
import _ from 'underscore';
import {NavLink, Link} from "react-router-dom";
import useSound from "use-sound";
import Swal from '@molline/sweetalert2'
import {GroupsContext} from "../../../../context/Groups/Groups";
import CountGatewaysWidget from '../../../../components/Dashboard/widgets/gateways/countGateways';
import CountWmbusSensorsWidget from '../../../../components/Dashboard/widgets/sensors/countWmbusSensors';
import TourSteps from "../../../../TourSteps.json";
import PageTour from "../../../../components/Dashboard/tour/tour";


const GatewayAnalzer = (props) => {

    const {gateways, gatewaysChanged, getInsight} = useContext(GatewaysContext);
    const {sensors, sensorsChanged} = useContext(SensorsContext);
    const {locations} = useContext(LocationsContext);
    const { groupSelect } = useContext(GroupsContext);

    const [playAlert] = useSound(
        '/app-assets/audio/icq-uh-oh.mp3',
        { volume: 0.25 }
    );

    const [revealed, setRevealed] = useState([]);


    const [depth, setDepth] = useState(1);
    const [concat, setConcat] = useState(true);
    const [collection, setCollection] = useState({});

    const collectionChanged = useCompare(collection)
    const concatChanged = useCompare(concat)
    const depthChanged = useCompare(depth)

    const { socket } = useContext(SocketContext);


    useEffect(  () => {
        if(gatewaysChanged === true || concatChanged === true || depthChanged === true){
            resolveMap()
        } 
/** 
            if(socket !== null ){
                socket.on('gateway', (data) => {
                    let uid = data.payload.uid
                    let target = gateways.filter(gw => gw.uid === uid)
                    if(target.length > 0){
                        playAlert()
                        switch(data.event){
                            case 'gateway.updated':
                                Swal.fire({
                                    icon: 'info',
                                    toast: true,
                                    position: "bottom-end",
                                    showConfirmButton: false,
                                    timer: 10000,
                                    timerProgressBar: true,
                                    title: 'Gateway geupdated',
                                    html: `<p>Gateway ${uid} wurde geupdated</p>`,
                                })
                                break;
                            case 'gateway.data':
                                let filename = data.payload.filename
                                Swal.fire({
                                    icon: 'info',
                                    toast: true,
                                    position: "bottom-end",
                                    showConfirmButton: false,
                                    timer: 10000,
                                    timerProgressBar: true,
                                    title: 'Gateway hat Daten empfangen',
                                    html: `<p>Gateway ${uid} hat die Datei ${filename} gesendet und wird jetzt bearbeitet.</p>`,
                                })
                                break;
                            default:
                                break;
                        }
                    }
                })
            }
**/

    }, [ gateways, gatewaysChanged, sensors, depth, concat, collectionChanged ]);

    function useCompare (val) {
        const prevVal = usePrevious(val)
        return prevVal !== val
    }

    function usePrevious(value) {
        const ref = useRef();
        useEffect(() => {
            ref.current = value;
        });
        return ref.current;
    }

    const formatDate = (value) => {
        if (value === undefined) {
            return `<span style="color: red">-</span>`;
        }
        const date = new Date(value)
        const today = new Date()
        const diff = today.getTime() - date.getTime()
        const dayDiff = diff / (1000 * 60 * 60 * 24)
        return `<span style="color: ${dayDiff >= 14 ? 'red' : 'green'}">${date.getDate()}.${date.getMonth()+1}.${date.getFullYear()}</span>`
    }

    const resolveMap = async () => {
        let gatewayCollection = {}
        let collectionOriginal = {...collection}
        for(let gtw of gateways){
            if(gtw.serials_insight.length === 0) continue;
            let inst = gtw.serials_insight.slice(0, depth)
            let dateSpan
            let serialsArr
            if(inst[ depth - 1 ] === undefined) {
                dateSpan = formatDate(inst[0].date)
                serialsArr = (inst.length !== 0) ? inst[0].serials : []
            } else if(concat && depth > 1) {
                dateSpan = `<span>${formatDate(inst[0].date)} - ${formatDate(inst[ depth - 1 ].date)}</span>`
                serialsArr = inst[ depth - 1 ].serials_insight
            } else {
                dateSpan = formatDate(inst[ depth - 1 ].date)
                serialsArr = inst[ depth - 1 ].serials_insight
            }
            gatewayCollection[gtw.uid] = {
                uid: gtw.uid,
                date_span: dateSpan,
                serials: serialsArr,
                active: ( collectionOriginal[gtw.uid] )? collectionOriginal[gtw.uid].active:true
            }
            /**
             * Concat all serial arrays from the telegram entries, "depth" deep
             */
            if(!!concat && depth > 1){
                let serials = []
                for(let sArray of inst){
                    serials = serials.concat( sArray.serials_insight )
                }
                //Array unique
                gatewayCollection[gtw.uid].serials = [...new Set(serials)]
            }   
        }
        setCollection(gatewayCollection)
    }

    const toggleGateway = (e) => {
        let uid = e.currentTarget.id
        let updatedCollection = JSON.parse( JSON.stringify(collection) )
        if(updatedCollection[uid] === undefined) return
        if(updatedCollection[uid].active) updatedCollection[uid].active = false
        else updatedCollection[uid].active = true;
        setCollection(updatedCollection)
    }

    const toggleActivate = () => {
        let updatedCollection = {...collection}
        if(Object.values(collection).filter(v => v.active === true).length !== gateways.length ){
            for(let gw of Object.values(updatedCollection)){
                gw.active = true;
            }
        }else{
            for(let gw of Object.values(updatedCollection)){
                gw.active = false;
            }
        }
        setCollection(updatedCollection) 
    }
    /**
     * When a sensor is clicked
     */
    const revealGateways = (e) => {
        let gwRevealed = []
        for(let i = 0; i < gateways.length; i ++){
            if(gateways[i].serials_insight !== undefined && gateways[i].serials_insight.includes(e.currentTarget.id)){
                gwRevealed.push(gateways[i].uid)
            }
            if(i === gateways.length - 1) setRevealed(gwRevealed)
        }
    }

    const getAddress = (location_id) => {
        let location = locations.filter(l => l._id === location_id)[0]
        if(location && location.address) return `LG: ${location.address.street} ${location.address.house_number} ${(location.address.street1 && location.address.street1 !== '')? 'ME:'+location.address.street1:''}`;
        return 'LIEGENSCHAFT'
    }

    const UI = () => {

        let percent = 0;
        let count = 0;
        let insightArray = []
        let wmbus = sensors.filter(sensor => (  (sensor.attributes && sensor.attributes.includes('wmbus')) || sensor.radio === true))
        for(const [k,v] of Object.entries(collection)){
           if(v.active === true) insightArray = insightArray.concat(v.serials)
        }
        if(insightArray.length > 0){
            count =  _.countBy(insightArray)
        }

        let sensorsInSight = [...new Set(insightArray)]  // Make unique
        let countInsight = 0;
        for(let s of wmbus){
            if(sensorsInSight.includes(s.serial_number)) countInsight ++;
        }
        let percentCoverage = (countInsight / wmbus.length) * 100;
        if(percentCoverage >= 99 && percentCoverage < 100) percentCoverage = 99
        else percentCoverage = Math.floor(percentCoverage) 
        
        let sensorLocations = {}
        for(const s of wmbus){
            if(!sensorLocations[s.location_id]) sensorLocations[s.location_id] = [];
            sensorLocations[s.location_id].push(s.serial_number)
        }

        return (
            <Fragment>
                <div className="content-header row">
                    <div className="content-header-left col-md-9 col-12 mb-2">
                        <div className="row breadcrumbs-top">
                            <div className="col-12">
                                <h2 className="content-header-title float-start mb-0">Gateway Analyzer</h2>
                                <div className="breadcrumb-wrapper">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><a>Tools</a></li>
                                        <li className="breadcrumb-item active">Gateway Analyse
                                        </li>
                                        <li>
                                            <span className="tourBorderRight" />
                                            <PageTour steps={TourSteps.gateway_analyser.overview}  isButton={false} />
                                        </li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                    <OrgCookieCrumb />

                </div>
                <div className="content-body">
                    <div className="row">
                        <CountGatewaysWidget />
                        <CountWmbusSensorsWidget />

                        <div className="col-xl-2 col-md-4" id="sensorPercentageWidget">
                            <div className="card text-center " title="Prozentsatz der Zähler, die innerhalb von 15 Tagen Daten gesendet haben">
                                <div className="card-body align-self-center">
                                    <div className="mx-auto my-1" style={{ display: "inline-flex", alignItems: "center" }}>
                                        <i className={`fa-solid fa-signal-stream fa-4x text-${(+percent < 96) ? 'warning' : 'success'}`}></i>
                                    </div>
                                    <p className="mb-0 text-muted">Abdeckung</p>
                                    <h2 className="mb-0 line-ellipsis ">{ percentCoverage }%</h2>
                                </div>
                            </div>
                        </div>

                        <div className="col-xl-2 col-md-4" id="TelegramDepthActiveInactive">
                            <div className="card text-center">
                                <div className="card-body">
                                    <div className="d-grid gap-2 d-md-block">
                                        <button className={`w-100 btn btn-${( Object.values(collection).filter(col => col.active === true).length === gateways.length )? 'success':'secondary'}`} id="btn-toggle_activate" onClick={() => {toggleActivate()}}> {(Object.values(collection).filter(v => v.active === true).length !== gateways.length )? 'Alle aktivieren':'Alle deaktivieren'} </button>
                                        <button className={`mt-1 w-100 btn btn-${(!concat)?'secondary':'success'}`} onClick={() => { (!concat)? setConcat(true):setConcat(false) }}>Concat</button>
                                        <div className="mt-1 w-100 btn btn-outline-info">
                                            <label for="depthRange" className="form-label">Telegram Depth = {depth}</label>
                                            <input type="range" className="form-range" min="1" max="10" step="1" id="telegramDepth" defaultValue={depth} onChange={(e) => {setDepth(e.target.value)}} />
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-2 col-md-4" id="gatewaysRequiresCounter">
                            <div className="card text-center">
                                <div className="card-body">
                                    <div className='float-start mb-1'>
                                        <span><button className="btn btn-secondary"></button> = Kein Gateway</span>
                                    </div>
                                    <div className='float-start mb-1'>
                                        <span><button className="btn btn-primary"></button> = 1 Gateway</span>
                                    </div>
                                    <div className='float-start mb-1'>
                                        <span><button className="btn btn-info"></button> = 2 Gateways</span>
                                    </div>
                                    <div className='float-start'>
                                        <span><button className="btn btn-success"></button> => 2 Gateways</span>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className="col-xl-2 col-md-4" id="groupSelectorWidget">
                            <div className="card">
                                <div className="card-header">
                                    <div className='row w-100'>
                                        <div className="col-12">
                                            <h4 className="card-title">Gruppe</h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-body">
                                    {groupSelect()}

                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-2"  id="leftsideGateways">
                            <div className="card text-center" >
                                <div className="card-header"><h4>Gateways</h4></div>
                                <div className="card-body">
                                    { gateways.slice(0, Math.floor(gateways.length/2)).map( gw => {
                                        let serlen = wmbus.filter(sen => {
                                            if(collection[gw.uid] && collection[gw.uid].serials && collection[gw.uid].serials.includes(sen.serial_number)) return sen
                                        })
                                        return (
                                            <Fragment>
                                                <div className="col-12 mb-1">
                                                    <div>
                                                        <button className={`btn-gateway btn btn-${(collection && collection[gw.uid] && collection[gw.uid]?.active === true)? 'success':'secondary'} ${(revealed.includes(gw.uid))? 'revealed': '' }`} id={gw.uid} onClick={(e) => {toggleGateway(e)}}>{gw.uid}</button>
                                                        <span className="insight-badge">{serlen.length}</span>
                                                        <div><small dangerouslySetInnerHTML={ ( collection[gw.uid]) ? {__html: collection[gw.uid].date_span} : {__html: `<span style="color: red">-</span>`}}></small></div>
                                                    </div>

                                                </div>
                                            </Fragment>
                                        )
                                    })}
                                </div>
                            </div>
                        </div>

                        <div className="col-8" id="SensorCoverageCounter" >
                            <div className="card text-center clickable" >
                                <div className="card-header"><h4>Zähler</h4></div>
                                <div className="card-body">
                                    <div className="row">
                                    {
                                        Object.keys(sensorLocations).map( (location_id) => {
                                            return (
                                                <Fragment>
                                                    <div className="card col-4 border-info">
                                                        <div className="card-header"><h6>{getAddress(location_id)}</h6></div>
                                                        <div className="card-body p-0 pb-1">
                                                            {
                                                                sensorLocations[location_id].map( serial_number => {
                                                                    let factor = 'secondary';
                                                                    if(count[serial_number] > 0) factor = 'primary';
                                                                    if(count[serial_number] > 1) factor = 'info';
                                                                    if(count[serial_number] > 2) factor = 'success';
                                                                    return (
                                                                        <Fragment>
                                                                            <button className={`btn-sensor btn btn-${factor} btn-sm`} data-toggle={"tooltip"} data-placement={"top"} title={serial_number} id={serial_number} onClick={(e) => {revealGateways(e)}} >{serial_number}</button>
                                                                        </Fragment>
                                                                    )
                                                                })
                                                            }
                                                        </div>
                                                    </div>
                                                </Fragment>
                                            )
                                        })
                                    }
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-2" id="rightSideGateways">
                            <div className="card text-center clickable" >
                                <div className="card-header"><h4>Gateways</h4></div>
                                <div className="card-body">
                                    { gateways.slice(Math.floor(gateways.length/2)).map( gw => {
                                        let serlen = wmbus.filter(sen => {
                                            if(collection[gw.uid] && collection[gw.uid].serials && collection[gw.uid].serials.includes(sen.serial_number)) return sen
                                        })
                                        return (
                                            <Fragment>
                                                <div className="col-12 mb-1">
                                                    <button className={`btn-gateway btn btn-${(collection && collection[gw.uid] && collection[gw.uid]?.active === true)? 'success':'secondary'} ${(revealed.includes(gw.uid))? 'revealed': '' }`} id={gw.uid} onClick={(e) => {toggleGateway(e)}}>{gw.uid}</button>
                                                    <span className="insight-badge">{serlen.length}</span>
                                                    <div><small dangerouslySetInnerHTML={ ( collection[gw.uid]) ? {__html: collection[gw.uid].date_span} : {__html: `<span style="color: red">-</span>`}}></small></div>
                                                </div>
                                            </Fragment>)
                                    })}
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
            </Fragment>
        )
    }

    return (
        <Fragment>
            {UI()}
        </Fragment>

    )
}


GatewayAnalzer.requiredScope = ['tool.gateway.any']
GatewayAnalzer.requireAuth = true;  // true -> force login via AuthContext, false -> no login required so no user available either
export default GatewayAnalzer;