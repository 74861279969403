import React, {Fragment, useState, useEffect, useContext, useRef} from 'react';
import { AuthContext } from '../../../../context/Auth/AuthProvider';
import OrgCookieCrumb from '../../../../components/Dashboard/orgCookieCrumb'
import {PermissionsWrapper, hasPermission} from "../../../../components/Dashboard/reactPermissionsWrapper";
import Swal from '@molline/sweetalert2';

import de from "../../../../components/Dashboard/tables/datatable.de.json";
import Axios_client from "../../../../utils/axios_client";
import $ from 'jquery'
$.DataTable = require('datatables.net');

const EmailLogs = ({children}) => {

    const [updated, setUpdated] = useState(false);
    const { user } = useContext(AuthContext);
    const [emailLogs, setEmailLogs] = useState([]);
    const emailLogsChanged = useCompare(emailLogs);
    const userChanged = useCompare(user);

    useEffect(() => {

        if(userChanged === true && !updated && emailLogs.length === 0){
            setUpdated(true)
            getLogs()
        }
        if(emailLogsChanged === true) initializeDT()
        
        
    }, [user, emailLogs]);

    function useCompare (val) {
        const prevVal = usePrevious(val)
        return prevVal !== val
    }

    function usePrevious(value) {
        const ref = useRef();
        useEffect(() => {
            ref.current = value;
        });
        return ref.current;
    }

    const successToast = (msg) => {
        Swal.fire({
            toast: true,
            position: 'bottom-end',
            timer: 3000,
            timerProgressBar: true,
            allowOutsideClick: true,
            text: msg,
            showConfirmButton: false,
            customClass: {
                popup: "bg-success"
            }
        })
    }

    const errorToast = (err) => {
        Swal.fire({
            toast: true,
            position: 'bottom-end',
            timer: 3000,
            timerProgressBar: true,
            allowOutsideClick: true,
            text: err,
            showConfirmButton: false,
            customClass: {
                popup: "bg-danger"
            }
        })
    }

    const getLogs = () => {
        Axios_client.backendClient().get(`${process.env.REACT_APP_API_OPENMETER}/mailstats/all`, {timeout:50000})
        .then( response => {
            setEmailLogs(response.data)
        })
        .catch( err => { console.log(err.message) })
    }

    const getBody = (message_id) => {
        return new Promise(async (resolve, reject) => {
            Axios_client.backendClient().get(`${process.env.REACT_APP_API_OPENMETER}/mailstats/body?message_id=${message_id}`, {timeout:50000})
            .then( response => {
                Swal.fire({
                    title: `Email`,
                    iconHtml: '<i class="fa-solid fa-envelope-open"/>',
                    customClass: {
                        icon:"border-0"
                    },
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false,
                    showConfirmButton: true,
                    confirmButtonText: `Email erneut senden an ${response.data.recipient}`,
                    cancelButtonText: "Abbrechen",
                    showCancelButton: true,
                    width: "80rem",
                    html: JSON.parse(response.data.body),
                })
                .then(results => {
                    if (!results.isConfirmed || results.isDenied || results.isDismissed) return;
                    Axios_client.backendClient().get(`${process.env.REACT_APP_API_OPENMETER}/mailstats/resend?message_id=${message_id}`, {timeout:50000})
                    .then((response) => {
                        successToast(`${response.data} -> Ihre Nachricht an ${response.data.recipient} mit dem Betreff ${response.data.subject} wurde erfolgreich gesendet.`)
                    })
                    .catch((err) => {
                        errorToast(`Ihre Nachricht an ${response.data.recipient} mit dem Betreff ${response.data.subject} konnte nicht gesendet werden: ${err}`)
                    })
                })
            })
            .catch( err => { console.log(err.message) })
        })
        
    }

    const initializeDT = () => {
        if ($.fn.dataTable.isDataTable(`#tblEmailLogs`)) {
            $(`#tblEmailLogs`).DataTable().destroy();
            // remove the eventhandler to prevent multiple events 
            $(`#tblEmailLogs tbody`).off('click')
            $(`#tblEmailLogs tbody`).off('mouseenter')
            $(`#tblEmailLogs tbody`).off('mouseleave')
            initializeDT()
        }else{
            $('#tblEmailLogs').DataTable({
                language: de,
                paging: true,
                pagingType: "full",
                processing: true,
                searching: true,
                data: emailLogs,
                orderCellsTop: true,
                fixedHeader: true,
                rowId: "message_id",
                pageLength: 100,
                ordering: [[0,'asc']],
                columns: [
                    {
                        "data": "message_id",
                        "searchable": true,
                        "ordering": true,
                        visible: false
                    },
                    {
                        "data": null,
                        "searchable": false,
                        "ordering": true,
                        render: function ( data, type, row ) {
                            return data.created_at
                        }
                    },

                    {
                        "data": null,
                        "searchable": true,
                        "ordering": true,
                        render: function ( data, type, row ) {
                            if(data.status === "sent") return `<span class="badge bg-success">gesendet</span>`;
                            if(data.status === "bounced") return `<span class="badge bg-warning">${data.status}</span>`;
                            if(data.status === "rejected") return `<span class="badge bg-danger">abgelehnt</span>`;
                            return data.status
                        }
                    },
                    {
                        "data": null,
                        "searchable": true,
                        "ordering": true,
                        render: function ( data, type, row ) {
                            return data.recipient
                        }
                    },
                    {
                        "data": "subject",
                        "searchable": true,
                        "ordering": true,
                    },

                    {
                        "data": null,
                        "searchable": true,
                        "ordering": true,
                        render:function(data, type, row) {
                            if(hasPermission({user, scopes:['user.update.own', 'user.update.any', 'user.delete.any', 'profile.view.any']})){
                                return `
                                <div class="dropdown b-dropdown btn-group" id="${data._id.toString()}">
                                    <button aria-haspopup="true" type="button" data-bs-toggle="dropdown" id="Dropdownbtns" class="btnOverflowMenu btn btn-sm dropdown-toggle btn-secondary dropdown-toggle-split" >
                                        <i class="fa-solid fa-bars"></i>
                                    </button>
                                    <ul role="menu" tabindex="-1" class="dropdown-menu dropdown-menu-right" >
                                        ${
                                            (hasPermission({ user, scopes: ['email.view.any']})) ?
                                            `
                                            <li role="presentation" class="viewMail">
                                                <a role="menuitem" class="dropdown-item"><i class="text-primary fa-solid fa-at me-25"></i> Ansehen</a>
                                            </li>
                                            ` : ''
                                        }
                                        
                                    </ul>
                                </div>`
                            }
                        }
                    },


                ],
                createdRow: function (row, data, dataIndex) {

                }
            });
            $('.dataTables_length select').addClass('form-select');
            $('.dataTables_filter input').addClass('form-control');

            $('#tblEmailLogs tbody').on( 'click', 'tr', function () {
                $(this).toggleClass('selected');
                //router.push(`/details/location/${this.id}`);
            });
            $(`#tblEmailLogs tbody`).on('mouseenter', 'tr', function () {
                $(this).css('cursor', 'pointer');
                $(this).css('background-color', '#f3f2f7');
            });
            $(`#tblEmailLogs tbody`).on('mouseleave', 'tr', function () {
                $(this).css('background-color', 'transparent');
            });
            
            $(`#tblEmailLogs tbody`).on('click', 'li.viewMail', function (e) {
                //e.preventDefault()
                let link = e.currentTarget;
                let parent = link.closest('tr');
                getBody(parent.id).then(()=>{});
            });
        }
    }


    const UI = () => {

        const isProcessing = () => {
            if(emailLogs.length === 0 && !emailLogsChanged){
                return (
                    <div className="mt-4">
                        <h4 className="text-center"><i className="fa-solid fa-cog fa-spin"></i> WIRD AKTUALISIERT ...</h4>
                    </div>
                )
            }
        }

        return (
            <Fragment>

                <div className="content-header row">
                    <div className="content-header-left col-md-9 col-12 mb-2">
                        <div className="row breadcrumbs-top">
                            <div className="col-9">
                                <h2 className="content-header-title float-start mb-0">Email Logs</h2>
                                <div className="breadcrumb-wrapper">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><a>Tools</a></li>
                                        <li className="breadcrumb-item active">Email Logs</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                    <OrgCookieCrumb />
                </div>

                <div className="content-body">
                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-body">
                                    <p className="text-danger">Achtung:  Email-Logs werden alle 30 min bei **:31h geupdated</p>
                                    <table id="tblEmailLogs" className="table mb-0 row-border hover order-column ">
                                        <thead>
                                        <tr>
                                            <th title="Message ID">Email-ID</th>
                                            <th >Date</th>
                                            <th >Status</th>
                                            <th >Empfänger</th>
                                            <th >Betreff</th>
                                            <th></th>
                                        </tr>
                                        </thead>
                                    </table>
                                    {isProcessing()}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </Fragment>
        )
    }


    return (
        <Fragment>
            {UI()}
        </Fragment>
    )



}

EmailLogs.requireAuth = true;
EmailLogs.requiredScope = ['email.view.any'];
export default EmailLogs;